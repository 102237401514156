export const MBusMessagesFields = {
  TENANT_SECURITY_DEPOT_TYPE: 'NachmieterTenantSecurityDepotType',
  TENANT_SECURITY_DEPOSIT_AMOUNT: 'NachmieterTenantSecurityDepositAmount',
  TENANT_SECURITY_PAID_AMOUNT: 'NachmieterTenantSecurityPaidAmount',
  LIABILITY_INSURANCE_CHECK: 'NachmieterLiabilityInsuranceCheck',
  TENANT_SECURITY_DEPOT_TYPE_OLD_TENANT: 'VormieterTenantSecurityDepotType',
  TENANT_SECURITY_DEPOSIT_AMOUNT_OLD_TENANT: 'VormieterTenantSecurityDepositAmount',
  TENANT_SECURITY_PAID_AMOUNT_OLD_TENANT: 'VormieterTenantSecurityPaidAmount',
  LIABILITY_INSURANCE_CHECK_OLD_TENANT: 'VormieterLiabilityInsuranceCheck',
};

export const MBusMessagesFieldsOperation = (field) => {
  switch (field) {
    case MBusMessagesFields.TENANT_SECURITY_DEPOT_TYPE:
    case MBusMessagesFields.TENANT_SECURITY_DEPOT_TYPE_OLD_TENANT:
    case MBusMessagesFields.TENANT_SECURITY_DEPOSIT_AMOUNT:
    case MBusMessagesFields.TENANT_SECURITY_DEPOSIT_AMOUNT_OLD_TENANT:
    case MBusMessagesFields.TENANT_SECURITY_PAID_AMOUNT:
    case MBusMessagesFields.TENANT_SECURITY_PAID_AMOUNT_OLD_TENANT:
      return 'UpdateDepot';
    case MBusMessagesFields.LIABILITY_INSURANCE_CHECK:
    case MBusMessagesFields.LIABILITY_INSURANCE_CHECK_OLD_TENANT:
      return 'UpdateDetails';
    default:
      return null;
  }
};

export const isOldTenantSecurityDepot = (field) => {
  return field.includes('Vormieter');
};
