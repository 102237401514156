import produce from 'immer';
import { call, put, takeLatest } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { get } from '../services/api';
import { authLogin } from '../services/auth';
import {
  COMMON_CHECK_TOKEN_EXP,
  COMMON_LOGIN,
  COMMON_LOGIN_ERROR,
  COMMON_LOGIN_SUCCESS,
  COMMON_LOGIN_TOKEN_SET,
  COMMON_LOGIN_TOKEN_UNSET,
  COMMON_TOKENS_EXPIRED
} from "./constants";

export function commonLogin(loginWorkflow, username, password, client) {
  return {
    type: COMMON_LOGIN,
    payload: { loginWorkflow, username, password, client },
  };
}

export function commonTokenSet(tokens, isSsoLogin) {
  return {
    type: COMMON_LOGIN_TOKEN_SET,
    payload: { tokens: tokens, isSsoLogin: isSsoLogin },
  };
}
export function commonTokenUnset() {
  return {
    type: COMMON_LOGIN_TOKEN_UNSET,
  };
}
export function commonCheckTokenExpiry() {
  return {
    type: COMMON_CHECK_TOKEN_EXP,
  };
}

export function commonSetTokenExpired(isExpired) {
  return {
    type: COMMON_TOKENS_EXPIRED,
    payload: isExpired,
  };
}

function* doLogin({ payload: { loginWorkflow, username, password, client } }) {
  try {
    //  username and password are taken from the server due to case insentitive on client side
    const response = yield call(authLogin, username, password, client);

    try {
      const locales = yield call(get, 'locale');
      const clientConfig = yield call(get, 'clientconfig');

      let securityDepotTypes = [];
      try {
        securityDepotTypes = yield call(get, 'letting/securitydeposittypes');  
      } catch (error) {
        console.error(error.message);
      }

      yield put({
        type: COMMON_LOGIN_SUCCESS,
        payload: {
          loginWorkflow,
          username: response.username,
          email: response.email,
          locales,
          securityDepotTypes,
          client: response.client,
          clientConfig,
        },
      });
    } catch (error) {
      yield put({
        type: COMMON_LOGIN_ERROR,
        payload: error.message,
      });
    }
  } catch (error) {
    yield put({
      type: COMMON_LOGIN_ERROR,
      payload: error.message,
    });
  }
}

export function* switchLogin() {
  yield takeLatest(COMMON_LOGIN, doLogin);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_LOGIN:
        draft.ui.busy.login = true;
        draft.authInfo.isLoggedIn = false;
        break;
      case COMMON_LOGIN_SUCCESS:
        draft.ui.busy.login = false;
        draft.authInfo.isLoggedIn = true;
        draft.username = action.payload.username;
        draft.email = action.payload.email;
        draft.locales = action.payload.locales;
        draft.securityDepotTypes = action.payload.securityDepotTypes;
        draft.client = action.payload.client;
        draft.clientConfig = action.payload.clientConfig;
        draft.sessionId = uuid();
        setLoginInfo(draft, action.payload.client, action.payload.username);
        break;
      case COMMON_LOGIN_ERROR:
        draft.ui.busy.login = false;
        draft.authInfo.isLoggedIn = false;
        break;
      case COMMON_LOGIN_TOKEN_SET:
        draft.authInfo.accessToken = action.payload.tokens.token;
        draft.authInfo.expiresAt = action.payload.tokens.expiresAt;
        draft.authInfo.isLoggedIn = true;
        draft.authInfo.isTokenExpired = false;
        
        if (action.payload.isSsoLogin){
          draft.authInfo.refreshToken = action.payload.tokens.refreshToken;
          draft.authInfo.idToken = action.payload.tokens.idToken;
        }
        break;
      case COMMON_LOGIN_TOKEN_UNSET:
        draft.authInfo.accessToken = null;
        draft.authInfo.refreshToken = null;
        draft.authInfo.idToken = null;
        draft.authInfo.expiresAt = null;
        draft.authInfo.isLoggedIn = false;
        draft.authInfo.isTokenExpired = false;
        break;
      case COMMON_TOKENS_EXPIRED:
        draft.authInfo.isTokenExpired = action.payload;
        break;
      default:
        return state;
    }
  });

function setLoginInfo(draft, client, username) {
  const loginInfoCustomer = draft.loginInfo.customers.find((x) => x.name === client);
  draft.loginInfo.lastClient = client;
  draft.loginInfo.lastUsername = username;

  if (!!loginInfoCustomer) {
    const loginInfoUsername = loginInfoCustomer.usernames.find((x) => x.username === username);

    if (!loginInfoUsername) {
      loginInfoCustomer.usernames.push({ username });
    }
  } else {
    draft.loginInfo.customers.push({
      name: client,
      usernames: [{ username }],
    });
  }
}
