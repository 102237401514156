/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import { routerActions } from 'connected-react-router';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { variables } from '../../common/theme/variables';
import * as acceptanceProtocolActions from '../acceptance-protocol/redux/actions';
import Button from '../common/Button';
import ConfirmModal from '../common/ConfirmModal';
import { SpinnerIcon } from '../common/SpinnerIcon';
import * as commonActions from '../common/redux/actions';
import {
  selectHasAnyQueue,
  selectIsAnyMarkAsOfflineBusy,
  selectIsAnySyncOfflineBusy,
  selectIsGetPdfFromProtocolsBusy,
  selectIsGetProtocolsBusy,
  selectIsLoggedIn,
  selectIsLoginBusy,
  selectIsMarkAsOfflineBusy,
  selectIsOnline,
  selectIsSyncOfflineBusy,
  selectLanguage,
  selectLoginInfo,
  selectOfflineProtocols,
  selectProtocols,
  selectQueues,
  selectErrorSynchronizationProtocols,
  selectIsKeycloakActive,
  selectKeycloak,
  selectClient,
  selectIsKeycloakConfigLoading,
  selectIsLoginModalOpen,
  selectIsTokenExpired,
} from "../common/redux/selectors";

import ProtocolWithoutREMReferenceModal from './ProtocolWithoutREMReferenceModal';
import * as actions from './redux/actions';
import {
  LOGIN_WORKFLOW_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE,
  LOGIN_WORKFLOW_SEARCH_RENTAL_OBJECT,
} from '../common/redux/loginWorkflowConstants';
import {
  selectIsChangeProtocolIdModalOpen,
  selectIsGetTemplatesBusy,
  selectIsProtocolWithoutREMReferenceModalBusy,
  selectIsProtocolWithoutREMReferenceModalOpen,
  selectTemplates,
} from './redux/selectors';
import { homeRoutes } from './routes';
import { SelectedProtocols } from './SelectedProtocols';

const styles = {
  protocols: {
    overflowY: 'scroll',
    '::WebkitScrollbar': {
      width: '0px',
      height: '0px',
      background: 'transparent',
    },
  },
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: `calc(100% - 225px) 225px`,

    [variables.breakpoints.md]: {
      gridTemplateRows: `calc(100% - 130px) 130px`,
    },
  },

  protocol: {
    height: '100%',
  },

  buttons: {
    container: {
      display: 'grid',
      gridAutoFlow: 'row',
      gridGap: '10px',
      backgroundColor: 'white',

      padding: `16px ${variables.gutter.sm}`,

      [variables.breakpoints.md]: {
        padding: `16px ${variables.gutter.lg}`,
        gridAutoFlow: 'column',
        gridGap: '30px',
      },
    },
    gap: {
      display: 'none',
      [variables.breakpoints.md]: {
        display: 'block',
      },
    },
  },

  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
};

export class DefaultPage extends Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isLoginModalOpen: PropTypes.bool.isRequired,
    isLoginModalBusy: PropTypes.bool.isRequired,
    isProtocolWithoutREMReferenceModalOpen: PropTypes.bool.isRequired,
    isProtocolWithoutREMReferenceModalBusy: PropTypes.bool.isRequired,
    isChangeProtocolIdModalOpen: PropTypes.bool.isRequired,
    protocols: PropTypes.array.isRequired,
    offlineProtocols: PropTypes.array.isRequired,
    errorSynchronizationProtocols: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
  };

  render() {
    const {
      language,
      isLoggedIn,
      isKeycloakActive,
      isProtocolWithoutREMReferenceModalOpen,
      isProtocolWithoutREMReferenceModalBusy,
      isChangeProtocolIdModalOpen,
      isGetProtocolsBusy,
      isGetPdfFromProtocolBusy,
      protocols,
      offlineProtocols,
      errorSynchronizationProtocols,
      isOnline,
      queues,
      templates,
      isGetTemplatesBusy,
      isMarkAsOfflineBusy,
      isSyncOfflineBusy,
      isAnyMarkAsOfflineBusy,
      isAnySyncOfflineBusy,
      hasAnyQueue,
      isKeycloakConfigLoading,
      actions: {
        acceptanceProtocolSelectProtocol,
        commonMarkAsOffline,
        commonSyncOffline,
        homeCreateProtocolWithoutREMReference,
        homeCloseProtocolWithoutREMReferenceModal,
        commonOpenNotImplementedModal,
        homeCloseChangeProtocolIdModal,
        homeOpenChangeProtocolIdModal,
        homeGetTemplates,
        commonUnmarkProtocolError,
      },
    } = this.props;

    if (isKeycloakConfigLoading) {
      return (
        <div css={styles.loader} className="loading-spinner">
          <SpinnerIcon />
        </div>
      );
    }

    return (
      <div css={styles.container}>
        { isLoggedIn || !isKeycloakActive ? (
          <>
            <div css={styles.protocols}>
              <SelectedProtocols
                isBusy={isGetProtocolsBusy || isGetPdfFromProtocolBusy}
                selectedProtocols={protocols}
                queues={queues}
                offlineProtocols={offlineProtocols}
                errorSynchronizationProtocols={errorSynchronizationProtocols}
                isOnline={isOnline}
                isMarkAsOfflineBusy={isMarkAsOfflineBusy}
                isSyncOfflineBusy={isSyncOfflineBusy}
                isAnyMarkAsOfflineBusy={isAnyMarkAsOfflineBusy}
                isAnySyncOfflineBusy={isAnySyncOfflineBusy}
                hasAnyQueue={hasAnyQueue}
                onSelectProtocol={acceptanceProtocolSelectProtocol}
                onMarkAsOffline={commonMarkAsOffline}
                onSyncOffline={commonSyncOffline}
                onSoftPush={commonUnmarkProtocolError}
              />
            </div>
            <div css={styles.buttons.container}>
              <Button primary css={styles.buttons.loginButton} onClick={this.authSwitchOperation}>
                {isLoggedIn ? (
                  <Trans>COMMON.BUTTONS.LOGOUT</Trans>
                ) : (
                  <Trans>COMMON.BUTTONS.LOGIN</Trans>
                )}
              </Button>
              <div css={styles.buttons.gap} />
              <Button primary onClick={this.doCreateReportWithoutREMReference}>
                <Trans>BUTTONS.PROCOTOL-WITHOUT-REM-REFERENCE</Trans>
              </Button>
              <Button primary onClick={this.doSearchRentalObject}>
                <Trans>BUTTONS.PROTOCOL-FROM-SERVER</Trans>
              </Button>
            </div>

            <ProtocolWithoutREMReferenceModal
              language={language}
              isOpen={isProtocolWithoutREMReferenceModalOpen}
              onClose={homeCloseProtocolWithoutREMReferenceModal}
              isBusy={isGetTemplatesBusy || isProtocolWithoutREMReferenceModalBusy}
              onCreateProtocolWithoutRemReference={homeCreateProtocolWithoutREMReference}
              onOpenNotImplemented={commonOpenNotImplementedModal}
              isOpenChangeProtocolIdModal={isChangeProtocolIdModalOpen}
              onCloseChangeProtocolId={homeCloseChangeProtocolIdModal}
              onOpenChangeProtocolId={homeOpenChangeProtocolIdModal}
              templates={templates}
              onGetTemplates={homeGetTemplates}
            />

            <ConfirmModal />
          </>
        ) : (
          isKeycloakActive && ( isOnline ? (
            // Show Spinner only when there's no keycloak token and loading is not already shown by the above if
            <div css={styles.loader} className="loading-spinner">
              <SpinnerIcon />
            </div>
          ) : (
            // Only show the message if user is offline and not logged in
            <div css={styles.loader} className="go-back-to-online-for-login">
              <Trans>MESSAGES.GO-BACK-ONLINE-TO-LOGIN</Trans>
            </div>
          )
        ))}
      </div>
    );
  }

  doCreateReportWithoutREMReference = () => {
    const {
      isLoggedIn,
      actions: { commonOpenLoginModal, homeOpenProtocolWithoutREMReferenceModal },
    } = this.props;

    if (isLoggedIn) {
      homeOpenProtocolWithoutREMReferenceModal();
    } else {
      commonOpenLoginModal(LOGIN_WORKFLOW_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE);
    }
  };

  doSearchRentalObject = () => {
    const {
      isLoggedIn,
      actions: { commonOpenLoginModal, push },
    } = this.props;

    if (isLoggedIn) {
      push(homeRoutes.search);
    } else {
      commonOpenLoginModal(LOGIN_WORKFLOW_SEARCH_RENTAL_OBJECT);
    }
  };

  authSwitchOperation = () => {
    const {
      isLoggedIn,
      actions: { commonOpenLoginModal, commonLogout },
    } = this.props;

    if (isLoggedIn) {
      commonLogout();
    } else {
      commonOpenLoginModal();
    }
  };
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    language: selectLanguage(state),
    isLoggedIn: selectIsLoggedIn(state),
    isTokenExpired: selectIsTokenExpired(state),
    isKeycloakActive: selectIsKeycloakActive(state),
    keycloak: selectKeycloak(state),
    client: selectClient(state),
    isLoginModalOpen: selectIsLoginModalOpen(state),
    isLoginModalBusy: selectIsLoginBusy(state),
    isProtocolWithoutREMReferenceModalOpen: selectIsProtocolWithoutREMReferenceModalOpen(state),
    isProtocolWithoutREMReferenceModalBusy: selectIsProtocolWithoutREMReferenceModalBusy(state),
    isChangeProtocolIdModalOpen: selectIsChangeProtocolIdModalOpen(state),
    isGetProtocolsBusy: selectIsGetProtocolsBusy(state),
    isGetPdfFromProtocolBusy: selectIsGetPdfFromProtocolsBusy(state),
    protocols: selectProtocols(state),
    queues: selectQueues(state),
    offlineProtocols: selectOfflineProtocols(state),
    errorSynchronizationProtocols: selectErrorSynchronizationProtocols(state),
    isOnline: selectIsOnline(state),
    templates: selectTemplates(state),
    isGetTemplatesBusy: selectIsGetTemplatesBusy(state),
    loginInfo: selectLoginInfo(state),
    isMarkAsOfflineBusy: selectIsMarkAsOfflineBusy(state),
    isSyncOfflineBusy: selectIsSyncOfflineBusy(state),
    isAnyMarkAsOfflineBusy: selectIsAnyMarkAsOfflineBusy(state),
    isAnySyncOfflineBusy: selectIsAnySyncOfflineBusy(state),
    hasAnyQueue: selectHasAnyQueue(state),
    isKeycloakConfigLoading: selectIsKeycloakConfigLoading(state),
  };
}
/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...routerActions, ...actions, ...commonActions, ...acceptanceProtocolActions },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
