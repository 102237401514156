import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Keycloak from 'keycloak-js';
import {
  selectIsOnline,
  selectKeyCloakConfig,
  selectLoginWorkflow,
} from '../features/common/redux/selectors';
import {
  removeKeycloakTokens,
  commonKeycloakOnEvent,
  commonKeycloakOnTokens,
  commonKeycloakOnSet,
} from '../features/common/redux/keycloak';
import { KEYCLOAK_REALM } from './constants';
import { commonSetTokenExpired } from '../features/common/redux/actions';
import { store } from './configStore';
import { selectIsCurrentProtocolOffline } from '../features/acceptance-protocol/redux/selectors';

const KeyCloakContext = createContext();

export const KeyCloakProvider = ({ children }) => {
  const keycloakConfig = useSelector(selectKeyCloakConfig);
  const logInWorkflow = useSelector(selectLoginWorkflow);
  const isOnline = useSelector(selectIsOnline);
  const dispatch = useDispatch();

  const [authenticated, setAuthenticated] = useState(false);
  const [keyCloakInstance, setKeyCloakInstance] = useState(null);
  const [isKeyCloakReady, setIsKeyCloakReady] = useState(false);
  const [initializationError, setInitializationError] = useState(null);

  const isKeyCloakEnabled = keycloakConfig?.isKeycloakActive;

  let keycloak = useMemo(
    () =>
      new Keycloak({
        realm: KEYCLOAK_REALM,
        clientId: keycloakConfig?.clientId,
        url: keycloakConfig?.keycloakAuthServerUrl,
      }),
    [keycloakConfig?.clientId, keycloakConfig?.keycloakAuthServerUrl],
  ); // Only recreate when these dependencies change

  useEffect(() => {
    try {
      isOnline &&
        isKeyCloakEnabled &&
        keycloak
          .init({
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
          })
          .then((authenticated) => {
            setIsKeyCloakReady(true);
            setAuthenticated(authenticated);
            setKeyCloakInstance(keycloak);
            if (authenticated) {
              dispatch(commonKeycloakOnEvent('onAuthSuccess', null));
              dispatch(
                commonKeycloakOnTokens(
                  {
                    token: keycloak.token,
                    idToken: keycloak.idToken,
                    refreshToken: keycloak.refreshToken,
                  },
                  logInWorkflow,
                ),
              );
              dispatch(commonSetTokenExpired(false));
            }
            // Dispatch here after successful initialization
            dispatch(commonKeycloakOnSet(keycloak));
            dispatch(commonKeycloakOnEvent('onReady', null));
          })
          .catch((error) => {
            dispatch(commonKeycloakOnEvent('onAuthError', error));
          });

      // Event listeners
      keycloak.onAuthRefreshSuccess = () => {
        dispatch(commonKeycloakOnEvent('onAuthRefreshSuccess', null));
        dispatch(commonSetTokenExpired(false));
      };
      keycloak.onAuthLogout = () => {
        console.debug('Keycloak logout method initiated.');
        dispatch(commonKeycloakOnEvent('onAuthLogout', null));
        dispatch(commonSetTokenExpired(false));
        dispatch(removeKeycloakTokens());
      };
      keycloak.onAuthRefreshError = () => {
        const isOnline = selectIsOnline(store.getState());
        const isCurrentProtocolOffline = selectIsCurrentProtocolOffline(store.getState());
        if (isOnline) {
          console.debug('Keycloak token refresh failed.');
          dispatch(commonSetTokenExpired(true));
          if (!isCurrentProtocolOffline) {
            dispatch(commonKeycloakOnEvent('onAuthRefreshError', null));
          }
        }
      };
      keycloak.onTokenExpired = () => {
        const isOnline = selectIsOnline(store.getState());
        const isCurrentProtocolOffline = selectIsCurrentProtocolOffline(store.getState());
        if (isOnline) {
          dispatch(commonSetTokenExpired(true));
          if (!isCurrentProtocolOffline) {
            keycloak
              .updateToken(10)
              .then((refreshed) => {
                if (refreshed) {
                  dispatch(
                    commonKeycloakOnTokens(
                      {
                        token: keycloak.token,
                        idToken: keycloak.idToken,
                        refreshToken: keycloak.refreshToken,
                      },
                      logInWorkflow,
                    ),
                  );
                  dispatch(commonSetTokenExpired(false));
                }
              })
              .catch(() => {
                console.debug('Keycloak token refresh failed. Redirecting to Keycloak login page.');
                dispatch(commonKeycloakOnEvent('onAuthRefreshError', null));
              });
          }
        }
      };

      return () => {};
    } catch (error) {
      console.error('Failed to initialize adapter:', error);
      setInitializationError(error?.message || 'Initialization failed without error.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  return (
    <KeyCloakContext.Provider
      value={{
        authenticated,
        keyCloakInstance,
        isKeyCloakReady,
        initializationError,
        keycloak,
      }}
    >
      {children}
    </KeyCloakContext.Provider>
  );
};

KeyCloakProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useKeyCloak = () => useContext(KeyCloakContext);
