/** @jsxImportSource @emotion/react */
import { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from './redux/actions';
import * as commonActions from '../../common/redux/actions';
import { colors } from '../../../common/theme/colors';
import { Form } from './Form';
import { InputBaseData } from './InputBaseData';
import { getLocalizedLabel, getAllId, getFirstFieldName } from './Functions';
import { bindActionCreators } from 'redux';
import { selectSelected } from './redux/selectors';
import {
  selectCurrentId,
  selectProtocolLanguage,
  selectBasedataInput,
  selectRentalData,
  selectIsWithRemReference,
  selectIsTenantsErased,
} from '../redux/selectors';
import {
  selectIsTenancyAgreementLoading,
  selectTenancyAgreementSuccess
} from '../../common/redux/selectors';
import { EraseIcon } from '../../common/EraseIcon';
import { AddTenantIcon } from '../../common/AddTenantIcon';
import { RemoveTenantIcon } from '../../common/RemoveTenantIcon';
import Spinner from '../../common/Spinner';
import { i18n } from '../../../common/i18n-loader';
import {
  uniqBy,
  differenceWith,
  filter,
  contains,
  flip,
  prop,
  pipe,
  complement,
  clone,
} from 'ramda';
import { MBusMessagesFields } from '../../common/redux/mBusMessageFields';
import { getHandoverType } from '../../../common/code/protocol.helper';
import { HandoverTypes } from '../../../common/types/handoverTypes';

const styles = {
  container: {
    button: {
      cursor: 'pointer',
      background: 'transparent',
      color: colors.black,
    },
    display: 'grid',
    height: '100%',
  },
  section: {
    display: 'grid',
    gridTemplateColumns: 'auto min-content min-content',
    backgroundColor: `${colors.lighterGrey}`,
    alignItems: 'center',
    fontWeight: 'bold',
    span: {
      padding: '1.5em 1.0em',
    },
  },
  content: {
    padding: '1.5em',
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: '24px',
  },
  group: {
    display: 'grid',
  },
  empty: {
    width: '60px',
    height: '60px',
  },
  buttons: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '1em',
    paddingRight: '1em',
    button: {
      background: 'transparent',
      width: '32px',
      height: '32px',
      color: 'white',
    },
  },
};

export class InputRentalPerson extends InputBaseData {
  constructor(props) {
    super(props);
    this.formTenant2 = createRef();
  }

  getValue(fieldName) {
    const { rentalData } = this.props;
    if (!!fieldName) {
      const value = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1);
      return rentalData[value];
    }
    return '';
  }

  isOldOrNewRentalPersonType = (type) => type === 'oldRentalPerson' || type === 'newRentalPerson';

  updateValuesFromRentalData(fields, values, useRentalData) {
    if (!this.isOldOrNewRentalPersonType(values.typeId)) return values;
    if (!!fields && !!fields.length) {
      const templateFieldsNotInInputFields = differenceWith(
        (x, y) => x.fieldname === y.fieldname,
        fields,
        values.fields,
      );

      values.fields.push.apply(
        values.fields,
        templateFieldsNotInInputFields.reduce((accumulator, current) => {
          const field = getFirstFieldName(values.fields, current.fieldname);
          if (!!field) {
            accumulator.push({ fieldname: current.fieldname, value: field.Value });
            return accumulator;
          }

          if (useRentalData) {
            const rentalDataValue = this.getValue(current.fieldname);
            if (!!rentalDataValue) {
              accumulator.push({ fieldname: current.fieldname, value: rentalDataValue });
            }
          }

          return accumulator;
        }, []),
      );
    }

    return values;
  }

  onAddRentalPerson = () => {
    const {
      protocolId,
      selected,
      actions: { commonPatchOperation },
    } = this.props;

    setTimeout(() => {
      if (!!this.formTenant2.current) {
        const element = this.formTenant2.current;
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);

    const expression = `basedataInputs/[${selected.element.template.id}_2]`;

    commonPatchOperation(protocolId, expression, {});
  };

  onRemoveRentalPerson = () => {
    const {
      protocolId,
      selected,
      actions: { commonDeleteOperation, fillStepConfirmModal },
    } = this.props;

    fillStepConfirmModal(
      {
        title: i18n._('FILL-STEP.CONFIRM-REMOVE-TENANT-2.TITLE'),
        message: i18n._('FILL-STEP.CONFIRM-REMOVE-TENANT-2.MESSAGE'),
        ok: i18n._('BUTTONS.DELETE'),
        cancel: i18n._('BUTTONS.CANCEL'),
      },
      () => {
        const expression = `basedataInputs/[${selected.element.template.id}_2]`;

        commonDeleteOperation(protocolId, expression);
      },
    );
  };

  onErasePrimaryForm = (fields) => {
    const {
      protocolId,
      selected,
      actions: { commonPatchOperation },
    } = this.props;

    commonPatchOperation(protocolId, `basedataInputs/[${selected.element.template.id}]/isErased`, true);

    const uniqFields = uniqBy((f) => f.fieldname, fields);
    for (const field of uniqFields) {
      const expression = `basedataInputs/[${
        selected.element.template.id
      }]/fields/[${encodeURIComponent(field.fieldname)}]`;

      commonPatchOperation(protocolId, expression, { ...field, value: '' });
    }
  };

  onEraseSecondaryForm = (fields) => {
    const {
      protocolId,
      selected,
      actions: { commonPatchOperation },
    } = this.props;

    const uniqFields = uniqBy((f) => f.fieldname, fields);
    for (const field of uniqFields) {
      const expression = `basedataInputs/[${
        selected.element.template.id
      }_2]/fields/[${encodeURIComponent(field.fieldname)}]`;

      commonPatchOperation(protocolId, expression, { ...field, value: '' });
    }
  };

  splitTenantObject = (originalObject, fieldsToSplit, handoverType) => {
    // Filter fields based on the provided list
    const isFieldInList = pipe(prop('fieldname'), flip(contains)(Object.values(fieldsToSplit)));

    // Clone the original data to create two new objects
    const tenantObject = clone(originalObject);
    tenantObject.fields = filter(complement(isFieldInList), tenantObject.fields);

    const lettingObject = clone(originalObject);
    let lettingFields = filter(isFieldInList, lettingObject.fields);

    if (handoverType === HandoverTypes.VACANCY) {
      lettingFields = [];
    } else if (handoverType === HandoverTypes.HANDOUT) {
      const newTenantFields = pipe(
        prop('fieldname'),
        flip(contains)([
          fieldsToSplit.TENANT_SECURITY_DEPOSIT_AMOUNT,
          fieldsToSplit.TENANT_SECURITY_DEPOT_TYPE,
          fieldsToSplit.LIABILITY_INSURANCE_CHECK,
          fieldsToSplit.TENANT_SECURITY_PAID_AMOUNT,
        ]),
      );

      lettingFields = filter(complement(newTenantFields), lettingFields);
    } else if (handoverType === HandoverTypes.ACCEPTANCE) {
      const oldTenantFields = pipe(
        prop('fieldname'),
        flip(contains)([
          fieldsToSplit.TENANT_SECURITY_DEPOSIT_AMOUNT_OLD_TENANT,
          fieldsToSplit.TENANT_SECURITY_DEPOT_TYPE_OLD_TENANT,
          fieldsToSplit.LIABILITY_INSURANCE_CHECK_OLD_TENANT,
          fieldsToSplit.TENANT_SECURITY_PAID_AMOUNT_OLD_TENANT,
        ]),
      );

      lettingFields = filter(complement(oldTenantFields), lettingFields);
    }

    if (lettingFields.length === 0) return [tenantObject];

    lettingObject.fields = lettingFields;
    lettingObject.typeId = 'lettingInfo';

    return [tenantObject, lettingObject];
  };

  static propTypes = {
    protocolId: PropTypes.string.isRequired,
    selected: PropTypes.object.isRequired,
    input: PropTypes.array.isRequired,
    rentalData: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    loadingTenancyAgreement: PropTypes.bool.isRequired
  };

  render() {
    const {
      selected,
      input,
      language,
      rentalData,
      loadingTenancyAgreement,
      isTenantErased,
      isTenancyAgreementSuccess,
      actions: { fillStepConfirmModal },
    } = this.props;

    const handoverType = getHandoverType(rentalData);
    const title = getLocalizedLabel(selected.element.template, language);
    const fields = !!selected.element.template ? selected.element.template.fields : null;
    const valuesArray = getAllId(input, selected.element.template.id);
    const splitValueArray = this.splitTenantObject(
      valuesArray[0],
      MBusMessagesFields,
      handoverType,
    );

    if (valuesArray.length > 1) {
      valuesArray.shift();
      valuesArray.map((value) => splitValueArray.push(value));
    }

    const isFieldInList = pipe(
      prop('fieldname'),
      flip(contains)(Object.values(MBusMessagesFields)),
    );
    const tenantFields = filter(complement(isFieldInList), fields);
    const lettingFields = filter(isFieldInList, fields);
    const updatedValuesArray = !!splitValueArray
      ? splitValueArray.map((values, index) =>
          this.updateValuesFromRentalData(fields, values, index === 0),
        )
      : [];

    return (
      <div css={styles.container}>
        <div css={styles.content}>
          <div css={styles.group}>
            <Form
              id={updatedValuesArray[0].id}
              fields={tenantFields}
              values={updatedValuesArray.length > 0 ? updatedValuesArray[0] : []}
              language={language}
              onModifyField={this.onModifyField}
              onModifySibling={this.onModifySibling}
            >
              {({ initialEmptyValues, resetForm }) => {
                const handleErase = () => {
                  fillStepConfirmModal(
                    {
                      title: i18n._('FILL-STEP.CONFIRM-ERASE-TENANT.TITLE'),
                      message: i18n._('FILL-STEP.CONFIRM-ERASE-TENANT.MESSAGE'),
                      ok: i18n._('BUTTONS.DELETE'),
                      cancel: i18n._('BUTTONS.CANCEL'),
                    },
                    () => {
                      resetForm({ values: initialEmptyValues });
                      this.onErasePrimaryForm(fields);
                    },
                  );
                };
  
                return (
                  <div css={styles.section}>
                    <span>{title}</span>
                    <div css={styles.buttons}>
                      <button onClick={handleErase}>
                        <EraseIcon />
                      </button>
                      {splitValueArray.filter(value => value.typeId && value.typeId.toLowerCase().includes('rentalperson')).length === 1 && (
                        <button
                          data-testid={'option-tenant-data'}
                          onClick={this.onAddRentalPerson}
                        >
                          <AddTenantIcon />
                        </button>
                      )}
                    </div>
                  </div>
                );
              }}
            </Form>
            {
              updatedValuesArray.shift() &&
              updatedValuesArray.length > 0 &&
              updatedValuesArray.map((value) => {
                const getLettingInfoFormKey = () => {
                  return value.fields ? value.fields.reduce((acc, currentValue) => acc + currentValue.value, "") : value.id;
                };

                if (!this.isOldOrNewRentalPersonType(value.typeId) && !isTenantErased[value.id]) {
                  return (
                    <Spinner
                      show={loadingTenancyAgreement}
                      isBlocked={!isTenancyAgreementSuccess}
                    >
                      <Form
                        dataTestId={'letting-information-form'}
                        key={getLettingInfoFormKey()}
                        id={value.id}
                        fields={lettingFields}
                        values={value}
                        language={language}
                        onModifyField={this.onModifyField}
                        onModifySibling={this.onModifySibling}
                      >
                        {() => (
                          <div css={styles.section}>
                            <span>{i18n._('FILL-STEP.SECURITY-DEPOSIT.TITLE')}</span>
                          </div>
                        )}
                      </Form>
                    </Spinner>
                  );
                }

                return null;
            })}
          </div>
          {
            updatedValuesArray.length > 0 &&
            updatedValuesArray.map((value) => {
              if (this.isOldOrNewRentalPersonType(value.typeId)) {
                return (
                  <Form
                    id={value.id}
                    fields={tenantFields}
                    values={value}
                    language={language}
                    onModifyField={this.onModifyField}
                    onModifySibling={this.onModifySibling}
                  >
                    {({ initialEmptyValues, resetForm }) => {
                      const handleErase = () => {
                        fillStepConfirmModal(
                          {
                            title: i18n._('FILL-STEP.CONFIRM-ERASE-TENANT-2.TITLE'),
                            message: i18n._('FILL-STEP.CONFIRM-ERASE-TENANT-2.MESSAGE'),
                            ok: i18n._('BUTTONS.DELETE'),
                            cancel: i18n._('BUTTONS.CANCEL'),
                          },
                          () => {
                            resetForm({ values: initialEmptyValues });
                            this.onEraseSecondaryForm(
                              !!value && !!value.fields ? value.fields : [],
                            );
                          },
                        );
                      };

                      return (
                        <div css={styles.section} ref={this.formTenant2}>
                          <span>{title + ' 2'}</span>
                          <div css={styles.buttons}>
                            <button onClick={handleErase}>
                              <EraseIcon />
                            </button>
                            <button onClick={this.onRemoveRentalPerson}>
                              <RemoveTenantIcon />
                            </button>
                          </div>
                        </div>
                      );
                    }}
                  </Form>
                );
              }

              return null;
            })}
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  const protocolId = selectCurrentId(state);
  return {
    selected: selectSelected(state),
    protocolId,
    language: selectProtocolLanguage(state),
    input: selectBasedataInput(state),
    rentalData: selectRentalData(state),
    isWithRemReference: selectIsWithRemReference(state),
    loadingTenancyAgreement: selectIsTenancyAgreementLoading(state),
    isTenantErased: selectIsTenantsErased(protocolId)(state),
    isTenancyAgreementSuccess: selectTenancyAgreementSuccess(protocolId)(state)
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InputRentalPerson);
