import { actions as toastrActions } from 'react-redux-toastr';
import { put, takeLatest } from 'redux-saga/effects';

import {
  HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_ERROR,
  HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_ERROR,
  HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_ERROR,
  HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_ERROR,
  HOME_GET_TEMPLATES_ERROR,
  HOME_SEARCH_RENTAL_OBJECTS_ERROR,
} from './constants';

function* doNotifyError({ payload }) {
  yield put(
    toastrActions.add({
      type: 'error',
      attention: true,
      message: payload,
    }),
  );
}

export function* switchNotifyError() {
  yield takeLatest(
    [
      HOME_SEARCH_RENTAL_OBJECTS_ERROR,
      HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_ERROR,
      HOME_GET_TEMPLATES_ERROR,
      HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_ERROR,
      HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_ERROR,
      HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_ERROR,
    ],
    doNotifyError,
  );
}
