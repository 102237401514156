import { push } from 'connected-react-router';
import { put, takeLatest } from 'redux-saga/effects';

import { COMMON_KEYCLOAK_TOKENS_SUCCESS, COMMON_LOGIN_SUCCESS } from "./constants";
import {
  LOGIN_WORKFLOW_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE,
  LOGIN_WORKFLOW_SEARCH_RENTAL_OBJECT,
} from './loginWorkflowConstants';
import { homeRoutes } from "../../home/routes";
import { homeOpenProtocolWithoutREMReferenceModal } from "../../home/redux/actions";

export function commonLoginSuccess(workflow) {
  return {
    type: COMMON_LOGIN_SUCCESS,
    payload: workflow,
  };
}

function* doCommonLoginSuccess({ payload }) {
  
  let workflow;
  if (typeof payload === 'object'){
    workflow = payload.workflow;
  } else {
    workflow = payload;
  }
  
  switch (workflow) {
    case LOGIN_WORKFLOW_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE:
      yield put(homeOpenProtocolWithoutREMReferenceModal());
      break;
    case LOGIN_WORKFLOW_SEARCH_RENTAL_OBJECT:
      yield put(push(homeRoutes.search));
      break;
    default:
      break;
  }
}

export function* switchLoginSuccess() {
  yield takeLatest([COMMON_LOGIN_SUCCESS, COMMON_KEYCLOAK_TOKENS_SUCCESS], doCommonLoginSuccess);
}
