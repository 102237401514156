/** @jsxImportSource @emotion/react */
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';

import * as actions from './redux/actions';
import Modal from '../../common/Modal';
import { selectIsProgressModalOpen, selectProgress } from './redux/selectors';
import { bindActionCreators } from 'redux';
import { Icon } from '../../common/Icon';
import { colors } from '../../../common/theme/colors';
import { SpinnerIcon } from '../../common/SpinnerIcon';
import { variables } from '../../../common/theme/variables';
import { selectHasOnSiteWorkflow } from '../../common/redux/selectors';
import {
  PROGRESS_STATUS_AUTH_ERROR,
  PROGRESS_STATUS_COMPLETED,
  PROGRESS_STATUS_ERROR,
  PROGRESS_STATUS_ON_PROGRESS,
} from './redux/constants';

const styles = {
  container: (hasOnSiteWorkflow) => ({
    padding: '1.5em',
    display: 'grid',
    gridGap: '1em',
    gridTemplateRows: 'auto min-content',
    gridTemplateColumns: `auto auto auto auto auto ${hasOnSiteWorkflow && 'auto'}`,
    justifyItems: 'center',
    alignItems: 'center',
  }),
  bottom: (hasOnSiteWorkflow) => ({
    display: 'grid',
    gridColumn: `${hasOnSiteWorkflow ? '1/7' : '1/6'}`,
    progress: {
      display: 'grid',
      gridGap: '1em',
      gridTemplateColumns: 'min-content auto',
      justifyItems: 'center',
      alignItems: 'center',
    },
    finish: {
      display: 'grid',
      gridGap: '1em',
      gridTemplateRows: 'min-content min-content',
      justifyItems: 'center',
      alignItems: 'center',

      onSiteError: {
        color: 'red',
      },

      button: {
        color: colors.brand,
        cursor: 'pointer',
      },
    },
  }),
};

const ProgressModal = (props) => {
  const {
    isOpen,
    progress,
    hasOnSiteWorkflow,
    actions: { sendStepGoToHome, sendStepCloseProgressModal },
  } = props;

  const sum = progress.sync + progress.pdfs + progress.emails + progress.closed;
  const isSuccess = sum === 4;
  const isError = sum === -8;

  const isOnSiteSyncSuccess = hasOnSiteWorkflow
    ? progress.onSite === PROGRESS_STATUS_COMPLETED
    : false;

  return (
    <Modal isOpen={isOpen} blocker width={'auto'}>
      <div css={styles.container(hasOnSiteWorkflow)}>
        <ProgressIcon isActive={progress.sync === PROGRESS_STATUS_ON_PROGRESS}>1</ProgressIcon>
        <ProgressIcon isActive={progress.pdfs === PROGRESS_STATUS_ON_PROGRESS}>2</ProgressIcon>
        <ProgressIcon isActive={progress.emails === PROGRESS_STATUS_ON_PROGRESS}>3</ProgressIcon>
        <ProgressIcon isActive={progress.closed === PROGRESS_STATUS_ON_PROGRESS}>4</ProgressIcon>
        {hasOnSiteWorkflow && (
          <ProgressIcon
            isActive={progress.onSite === PROGRESS_STATUS_ON_PROGRESS}
            isError={
              progress.onSite === PROGRESS_STATUS_ERROR ||
              progress.onSite === PROGRESS_STATUS_AUTH_ERROR
            }
          >
            5
          </ProgressIcon>
        )}
        <ProgressIcon isActive={isSuccess || isError} isError={isError}>
          {isSuccess && (!hasOnSiteWorkflow || progress.onSite !== PROGRESS_STATUS_ON_PROGRESS) && (
            <Icon icon={'check'} />
          )}
          {isError && <Icon icon={'times'} />}
          {!isError &&
            (!isSuccess ||
              (hasOnSiteWorkflow && progress.onSite === PROGRESS_STATUS_ON_PROGRESS)) && (
              <SpinnerIcon />
            )}
        </ProgressIcon>
        <div css={styles.bottom(hasOnSiteWorkflow)}>
          {isSuccess &&
          (hasOnSiteWorkflow ? progress.onSite !== PROGRESS_STATUS_ON_PROGRESS : true) ? (
            <div css={styles.bottom(hasOnSiteWorkflow).finish}>
              <Trans>MESSAGES.PROTOCOL-SENT</Trans>
              {hasOnSiteWorkflow &&
                (isOnSiteSyncSuccess ? (
                  <div css={styles.bottom(hasOnSiteWorkflow).finish}>
                    <Trans>MESSAGES.ONSITE-MESSAGE-SYNCHRONIZATION-SUCCESS</Trans>
                  </div>
                ) : progress.onSite === PROGRESS_STATUS_AUTH_ERROR ? (
                  <div css={styles.bottom(hasOnSiteWorkflow).finish.onSiteError}>
                    <Trans>ERRORS.API.ONSITE-MESSAGE-SYNC-AUTH-ERROR</Trans>
                  </div>
                ) : (
                  <div css={styles.bottom(hasOnSiteWorkflow).finish.onSiteError}>
                    <Trans>MESSAGES.ONSITE-MESSAGE-SYNCHRONIZATION-ERROR</Trans>
                  </div>
                ))}
              <div onClick={sendStepGoToHome} css={styles.bottom(hasOnSiteWorkflow).finish.button}>
                <Trans>BUTTONS.CLOSE</Trans>
              </div>
            </div>
          ) : isError ? (
            <div css={styles.bottom(hasOnSiteWorkflow).finish}>
              <Trans>MESSAGES.PROTOCOL-ERROR</Trans>
              <div
                onClick={sendStepCloseProgressModal}
                css={styles.bottom(hasOnSiteWorkflow).finish.button}
              >
                <Trans>BUTTONS.CLOSE</Trans>
              </div>
            </div>
          ) : (
            <div css={styles.bottom(hasOnSiteWorkflow).progress}>
              <div>
                <SpinnerIcon />
              </div>
              <div>
                {progress.sync === PROGRESS_STATUS_ON_PROGRESS ? (
                  <Trans>MESSAGES.PROTOCOL-IS-BEING-SYNCHRONIZED</Trans>
                ) : progress.pdfs === PROGRESS_STATUS_ON_PROGRESS ? (
                  <Trans>MESSAGES.PDF-IS-BEING-SENT</Trans>
                ) : progress.emails === PROGRESS_STATUS_ON_PROGRESS ? (
                  <Trans>MESSAGES.EMAILS-ARE-BEING-SENT</Trans>
                ) : progress.onSite === PROGRESS_STATUS_ON_PROGRESS ? (
                  <Trans>MESSAGES.ONSITE-MESSAGES-ARE-BEING-SENT</Trans>
                ) : (
                  <Trans>MESSAGES.PROTOCOL-IS-BEING-SENT</Trans>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const iconStyles = {
  icon: {
    width: '105px',
    height: '105px',
    justifyContent: 'center',
    display: 'grid',
    alignItems: 'center',
    fontSize: '50px',
    backgroundColor: '#E3E3E3',
    color: colors.dark,
    borderRadius: '100%',

    [variables.breakpoints.xl]: {
      width: '150px',
      height: '150px',
    },

    '&.is-active': {
      backgroundColor: '#FFF',
      color: colors.darker,
    },
    '&.is-error': {
      backgroundColor: colors.error,
      color: colors.white,
    },
  },
};

const ProgressIcon = ({ children, isActive, isError }) => {
  return (
    <div
      css={iconStyles.icon}
      className={classNames({ 'is-active': isActive, 'is-error': isError })}
    >
      {children}
    </div>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    isOpen: selectIsProgressModalOpen(state),
    progress: selectProgress(state),
    hasOnSiteWorkflow: selectHasOnSiteWorkflow(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressModal);
