import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { persistor, store } from './common/configStore';
import routeConfig from './common/routeConfig';
import { actions as toastrActions } from 'react-redux-toastr';
import { i18n } from './common/i18n-loader';
import reportWebVitals from './reportWebVitals';
import Root from './Root';
import {
  Sentry,
  ReportingObserverIntegration,
  ExtraErrorDataIntegration,
  DedupeIntegration,
  RewriteFramesIntegration,
} from './common/sentry-loader';
import { sentryConfiguration } from './common/env.service';
import version from './version.json';
import { commonPersistSentryError } from './features/common/redux/sentryConf';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { LOGIN_WORKFLOW_EXPIRED_TOKEN } from "./features/common/redux/loginWorkflowConstants";
import { commonOpenLoginModal } from "./features/common/redux/actions";

Sentry.init({
  dsn: sentryConfiguration.dsn,
  release: version.version,
  environment: sentryConfiguration.environment,
  debug: false, //process.env.NODE_ENV !== 'production',
  ignoreErrors: [],
  integrations: [
    new ReportingObserverIntegration(),
    new ExtraErrorDataIntegration(),
    new DedupeIntegration(),
    new RewriteFramesIntegration(),
  ],
  beforeSend(event, hint) {
    if (!navigator.onLine) {
      const error = hint.originalException;

      store.dispatch(commonPersistSentryError(error));
      return null; //we return null and don't keep the event, because the error is persisted to the store
    }
    return event;
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Root store={store} persistor={persistor} routeConfig={routeConfig} />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register({
  onUpdate: (reg) => {
    serviceWorkerRegistration.addClientReloadListener();
    store.dispatch(
      toastrActions.add({
        options: {
          timeOut: 0,
          onToastrClick: () => reg.waiting.postMessage({ type: 'SKIP_WAITING' }),
        },
        type: 'warning',
        attention: true,
        message: i18n._('MESSAGES.NEW-VERSION'),
      }),
    );
  },
});

window.addEventListener("TokenExpired", () => {
  store.dispatch(commonOpenLoginModal(LOGIN_WORKFLOW_EXPIRED_TOKEN));
})