import { actions as toastrActions } from 'react-redux-toastr';
import { put, takeLatest } from 'redux-saga/effects';
import { i18n } from '../../../common/i18n-loader';

import {
  MIGRATION_GET_MIGRATION_ERROR,
  MIGRATION_START_MIGRATION_ERROR,
  MIGRATION_STOP_MIGRATION_ERROR,
  MIGRATION_RETAIN_MIGRATION_ERROR,
  MIGRATION_DELETE_MIGRATION_ERROR,
  MIGRATION_HUB_CONNECT_ERROR,
  MIGRATION_HUB_DISCONNECT_ERROR,
  MIGRATION_HUB_LISTENER_ERROR,
  MIGRATION_HUB_MESSAGE_IS_FAULTED,
} from './constants';

function* doNotifyError(action) {
  const message =
    action.type === MIGRATION_HUB_MESSAGE_IS_FAULTED
      ? i18n._(action.payload.message)
      : action.payload;

  yield put(
    toastrActions.add({
      type: 'error',
      attention: true,
      message: message,
    }),
  );
}

export function* switchNotifyError() {
  yield takeLatest(
    [
      MIGRATION_GET_MIGRATION_ERROR,
      MIGRATION_START_MIGRATION_ERROR,
      MIGRATION_STOP_MIGRATION_ERROR,
      MIGRATION_RETAIN_MIGRATION_ERROR,
      MIGRATION_DELETE_MIGRATION_ERROR,
      MIGRATION_HUB_CONNECT_ERROR,
      MIGRATION_HUB_DISCONNECT_ERROR,
      MIGRATION_HUB_LISTENER_ERROR,
      MIGRATION_HUB_MESSAGE_IS_FAULTED,
    ],
    doNotifyError,
  );
}
