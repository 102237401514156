import produce from 'immer';
import { put, take, takeLatest, race, select } from 'redux-saga/effects';
import {
  COMMON_FLUSHED_QUEUES,
  COMMON_FLUSH_QUEUES,
  COMMON_SYNC_OFFLINE,
  COMMON_SYNC_OFFLINE_START,
  COMMON_SYNC_OFFLINE_SUCCESS,
  COMMON_SYNC_OFFLINE_ERROR,
  COMMON_POP_QUEUE_ERROR,
} from './constants';
import { omit } from 'ramda';
import { selectSessionId } from './selectors';
import { commonRemoveFromDownload } from './actions';

export function commonSyncOffline(protocolId) {
  return { type: COMMON_SYNC_OFFLINE, payload: protocolId };
}

function* doSyncOffline({ payload }) {
  const sessionId = yield select(selectSessionId);

  yield put({
    type: COMMON_SYNC_OFFLINE_START,
    payload,
  });

  yield put(commonRemoveFromDownload({ sessionId, protocolId: payload }));

  yield put({
    type: COMMON_FLUSH_QUEUES,
    payload,
  });

  const { success } = yield race({
    success: take(COMMON_FLUSHED_QUEUES),
    error: take(COMMON_POP_QUEUE_ERROR),
  });

  if (success) {
    yield put({
      type: COMMON_SYNC_OFFLINE_SUCCESS,
      payload,
    });
  } else {
    yield put({
      type: COMMON_SYNC_OFFLINE_ERROR,
      payload,
    });
  }
}

export function* switchSyncOffline() {
  yield takeLatest(COMMON_SYNC_OFFLINE, doSyncOffline);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_SYNC_OFFLINE_START:
        draft.ui.busy.syncOffline[action.payload] = true;
        draft.offlineProtocols = draft.offlineProtocols.filter((p) => p.id === action.payload);
        break;
      case COMMON_SYNC_OFFLINE_SUCCESS:
        draft.ui.busy.syncOffline[action.payload] = false;
        draft.pdfs = omit([action.payload], draft.pdfs);
        break;
      case COMMON_SYNC_OFFLINE_ERROR:
        draft.ui.busy.syncOffline[action.payload] = false;
        break;
      default:
        return state;
    }
  });
