import { actions as toastrActions } from 'react-redux-toastr';
import { put, select, takeLatest } from 'redux-saga/effects';
import { SIGN_STEP_FILL_STEP_VALIDATION_ERROR } from './constants';
import { i18n } from '../../../../common/i18n-loader';
import { selectCurrentId, selectProtocolLanguage } from '../../redux/selectors';
import { validationFieldTranslation } from './Functions';

export function notifyValidationError(basedata, path, message, fillStepNavigateElement) {
  return {
    type: SIGN_STEP_FILL_STEP_VALIDATION_ERROR,
    basedata: basedata,
    path: path,
    message: message,
    fillStepNavigateElement: fillStepNavigateElement,
  };
}

function* doNotifyError({ basedata, path, message, fillStepNavigateElement }) {
  const protocolId = yield select(selectCurrentId);
  const language = yield select(selectProtocolLanguage);
  const elementName = path.includes('Vormieter') ? 'oldRentalPerson' : 'newRentalPerson';

  yield put(
    toastrActions.add({
      options: {
        timeOut: 0,
        showCloseButton: true,
        onToastrClick: () =>
          fillStepNavigateElement(protocolId, 'basedata', elementName, 'basedata'),
      },
      type: 'error',
      message: i18n._('FILL-STEP.VALIDATION.ERROR', {
        path: validationFieldTranslation(basedata, path, language),
        error: message,
      }),
    }),
  );
}

export function* switchNotifyValidationError() {
  yield takeLatest([SIGN_STEP_FILL_STEP_VALIDATION_ERROR], doNotifyError);
}
