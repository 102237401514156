import { connectRouter } from 'connected-react-router';
import localForage from 'localforage';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import fillStepReducer from '../features/acceptance-protocol/fill-step/redux/reducer';
import mainStepReducer from '../features/acceptance-protocol/main-step/redux/reducer';
import signReducer from '../features/acceptance-protocol/sign-step/redux/reducer';
import leavingAgreementStepReducer from '../features/acceptance-protocol/leaving-agreement-step/redux/reducer';
import sendStepReducer from '../features/acceptance-protocol/send-step/redux/reducer';
import commonReducer from '../features/common/redux/reducer';
import homeReducer from '../features/home/redux/reducer';
import migrationReducer from '../features/migration/redux/reducer';

const basePersistConfig = {
  storage: localForage,
  serialize: false,
  timeout: null,
};

const commonPersistConfig = {
  ...basePersistConfig,
  key: 'common',
  whitelist: [
    'language',
    'protocols',
    'pdfs',
    'queues',
    'username',
    'client',
    'locales',
    'securityDepotTypes',
    'loginInfo',
    'offlineProtocols',
    'errorSynchronizationProtocols',
    'clientConfig',
    'keycloakConfig',
    'leavingAgreements',
    'sentryErrors',
    'sessionId',
    'authInfo',
  ],
};

// NOTE 1: DO NOT CHANGE the 'reducerMap' name and the declaration pattern.
// This is used for Rekit cmds to register new features, remove features, etc.
// NOTE 2: always use the camel case of the feature folder name as the store branch name
// So that it's easy for others to understand it and Rekit could manage them.

const reducerMap = {
  common: persistReducer(commonPersistConfig, commonReducer),
  home: homeReducer,
  mainStep: mainStepReducer,
  fillStep: fillStepReducer,
  signStep: signReducer,
  leavingAgreementStep: leavingAgreementStepReducer,
  sendStep: sendStepReducer,
  migration: migrationReducer,
};

const rootReducer = (history) =>
  combineReducers({
    toastr: toastrReducer,
    router: connectRouter(history),
    ...reducerMap,
  });
export default rootReducer;
