import React from 'react';

export const EraseIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 26"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <rect x="7" y="25" width="14" height="1" />
    <rect x="1" y="25" width="1" height="1" />
    <rect x="3" y="25" width="1" height="1" />
    <rect x="5" y="25" width="1" height="1" />
    <rect x="7" y="22" width="14" height="1" />
    <rect x="10" y="19" width="11" height="1" />
    <rect x="13" y="16" width="8" height="1" />
    <path d="M17.12,6,15.4,1.62A2.4,2.4,0,0,0,13.71,0a2.39,2.39,0,0,0-2.18.78L1.36,11A5.21,5.21,0,0,0,.29,16.18L2,20.51a2.39,2.39,0,0,0,1.7,1.58,1.84,1.84,0,0,0,.41,0,2.53,2.53,0,0,0,1.77-.82L16.05,11.15A5.26,5.26,0,0,0,17.12,6Zm-12,14.65a1.42,1.42,0,0,1-1.27.5,1.44,1.44,0,0,1-1-1L1.22,15.81a4.19,4.19,0,0,1,.85-4.12L5.34,8.42l6,6ZM15.34,10.44l-3.28,3.28-6-6,6.18-6.18A1.56,1.56,0,0,1,13.3,1l.21,0a1.45,1.45,0,0,1,1,1l1.72,4.33A4.23,4.23,0,0,1,15.34,10.44Z" />
  </svg>
);
