import Axios from 'axios';
import HttpStatus from 'http-status-codes';
import { i18n } from '../../../common/i18n-loader';
import {
  badRequestError,
  genericError,
  notFoundError,
  unauthorizedError,
  unavailableError,
  conflictError,
} from '../../../common/messages';
import { authGetToken, authIsAuthenticated, setTokenExpired } from "./auth";
import * as Sentry from '@sentry/browser';
import localForage from 'localforage';

async function call(httpCall) {
  try {
    if (!authIsAuthenticated()) {
      const error = new Error(i18n._(unauthorizedError));
      error.response = { status: HttpStatus.UNAUTHORIZED };
      throw error;
    }

    const token = authGetToken();

    const headers = { Authorization: `Bearer ${token}` };
    const response = await httpCall(headers);
    return response.data;
  } catch (error) {
    let type;
    let message;
    let status;

    if (!!error.response) {
      const { response } = error;

      switch (response.status) {
        case HttpStatus.BAD_REQUEST:
          if (!!response.data) {
            type = !!response.data.type ? response.data.type : null;
            message = !!response.data.message ? response.data.message : badRequestError;
          } else {
            message = badRequestError;
          }
          break;
        case HttpStatus.CONFLICT:
          message = conflictError;
          break;
        case HttpStatus.UNAUTHORIZED:
        case HttpStatus.FORBIDDEN:
          window.dispatchEvent(new CustomEvent("TokenExpired", {}));
          setTokenExpired();
          message = unauthorizedError;
          break;
        case HttpStatus.NOT_FOUND:
          message = notFoundError;
          break;
        default:
        case HttpStatus.INTERNAL_SERVER_ERROR:
          message = genericError;
      }
      status = response.status;

      if (
        response.status !== HttpStatus.INTERNAL_SERVER_ERROR &&
        response.status !== HttpStatus.BAD_REQUEST
      ) {
        const {
          username = '',
          client = '',
          email = '',
        } = await localForage.getItem('persist:common');
        Sentry.withScope((scope) => {
          scope.setExtras(message);
          scope.setUser({
            client,
            username,
            email,
          });
          scope.setTag('client', client);
          scope.setTag('side', 'client');
          Sentry.captureException(error, scope);
        });
      }
    } else {
      message = unavailableError;
      status = HttpStatus.SERVICE_UNAVAILABLE;
    }

    const err = new Error(i18n._(message));
    enhanceError(err, status, type);
    throw err;
  }
}

export async function post(controller, data) {
  return await call(async (headers) => await Axios.post(`/api/${controller}`, data, { headers }));
}

export async function put(controller, data) {
  return await call(async (headers) => await Axios.put(`/api/${controller}`, data, { headers }));
}

export async function patch(controller, data) {
  return await call(async (headers) => await Axios.patch(`/api/${controller}`, data, { headers }));
}

export async function get(controller) {
  return await call(async (headers) => await Axios.get(`/api/${controller}`, { headers }));
}

export async function downloadFile(controller) {
  return await call(
    async (headers) =>
      await Axios.get(`/api/${controller}`, { headers, responseType: 'arraybuffer' }),
  );
}

export async function downloadFiles(controller, data) {
  return await call(
    async (headers) =>
      await Axios.post(`/api/${controller}`, data, { headers, responseType: 'arraybuffer' }),
  );
}

export async function uploadFile(controller, blob) {
  var formData = new FormData();
  formData.append('content', blob);

  return await call(
    async (headers) =>
      await Axios.post(`/api/${controller}`, formData, {
        headers: { ...headers, 'content-type': 'multipart/form-data' },
      }),
  );
}

export async function getById(controller, id) {
  return await call(async (headers) => await Axios.get(`/api/${controller}/${id}`, { headers }));
}

export async function deleteById(controller, id) {
  return await call(async (headers) => await Axios.delete(`/api/${controller}/${id}`, { headers }));
}

export async function deleteByIdAnonymous(controller, id) {
  return await Axios.delete(`/api/${controller}/${id}`);
}

function enhanceError(error, status, type) {
  error.status = status;
  if (!!type) {
    error.type = type;
  }
  return error;
}
