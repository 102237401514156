import React from 'react';

export const InOutArrowsIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 24"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <path d="M9,24a1,1,0,0,1-.71-.29l-8-8a1,1,0,0,1,0-1.42l8-8a1,1,0,0,1,1.09-.21A1,1,0,0,1,10,7v4h9a1,1,0,0,1,1,1v2.59L25.59,9,20,3.41V6a1,1,0,0,1-1,1H13V5h5V1a1,1,0,0,1,.62-.92,1,1,0,0,1,1.09.21l8,8a1,1,0,0,1,0,1.42l-8,8a1,1,0,0,1-1.09.21A1,1,0,0,1,18,17V13H9a1,1,0,0,1-1-1V9.41L2.41,15,8,20.59V18a1,1,0,0,1,1-1h7v2H10v4a1,1,0,0,1-.62.92A.84.84,0,0,1,9,24Z" />
  </svg>
);
