import produce from 'immer';
import { put, takeLatest, take } from 'redux-saga/effects';
import { authIsAuthenticated } from '../services/auth';
import { COMMON_CHECK_AUTH, COMMON_IS_LOGGED_IN, COMMON_IS_LOGGED_OUT } from './constants';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGIN_WORKFLOW_DEFAULT } from "./loginWorkflowConstants";

export function commonCheckIsAuth() {
  return {
    type: COMMON_CHECK_AUTH,
  };
}

function* doCheckIsAuth() {
  const isAuthenticated = authIsAuthenticated();

  if (isAuthenticated) {
    yield put({
      type: COMMON_IS_LOGGED_IN,
    });
  } else {
    yield put({
      type: COMMON_IS_LOGGED_OUT,
    });
  }
}

export function* switchCheckIsAuth() {
  yield takeLatest(COMMON_CHECK_AUTH, doCheckIsAuth);
  yield take(LOCATION_CHANGE);
  yield put(commonCheckIsAuth());
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_IS_LOGGED_IN:
        draft.authInfo.isLoggedIn = true;
        break;
      case COMMON_CHECK_AUTH:
        draft.loginWorkflow = LOGIN_WORKFLOW_DEFAULT;
        break;
      case COMMON_IS_LOGGED_OUT:
        draft.authInfo.isLoggedIn = false;
        draft.loginWorkflow = LOGIN_WORKFLOW_DEFAULT;
        break;
      default:
        return state;
    }
  });
