/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';

import { colors } from '../../common/theme/colors';
import { variables } from '../../common/theme/variables';
import { usePrevious } from '../../common/code/usePrevious';

const modalTitleHeight = 52;

const styles = {
  modal: {
    position: 'fixed',
    backgroundColor: 'rgba(50, 50, 50, 0.25)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    visibility: 'visible',
    display: 'none',
    '&.is-open': {
      display: 'grid',
      placeItems: 'center',
      width: '100%',
      height: '100%',
    },
    '&>div': {
      color: '#333333',
      borderRadius: '8px',
      maxHeight: '95%',

      overflow: 'hidden',
      display: 'grid',
      gridTemplateRows: `${modalTitleHeight}px calc(100% - ${modalTitleHeight}px)`,
      boxShadow: '0px 4px 8px 8px rgba(0,0,0,0.08)',

      width: '95vw',
      [variables.breakpoints.lg]: {
        width: '90vw',
      },
      [variables.breakpoints.xl]: {
        width: '60vw',
      },
    },
  },
  body: {
    padding: '32px',
    background: colors.lightGrey,
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
    gridRowGap: '32px',
    '& > *:last-child:after': {
      content: '""',
      display: 'block',
      height: '32px',
      marginBottom: '-32px',
    },
  },
  close: {
    color: '#aaa',
    lineHeight: '50px',
    fontSize: '80%',
    position: 'absolute',
    right: 0,
    textAlign: 'center',
    top: '0',
    width: '70px',
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
    },
  },
  top: {
    display: 'grid',
    gridTemplateColumns: 'auto min-content',
    height: '52px',
    backgroundColor: `${colors.darker}`,

    title: {
      textTransform: 'uppercase',
      background: colors.darker,
      color: '#FFF',
      textAlign: 'center',
      alignSelf: 'center',
    },
  },
};

const Modal = (props) => {
  const { isOpen, onOpen, onClose, title, blocker, children, zIndex, width, showButton, buttons } =
    props;

  const node = useRef();

  const previousIsOpen = usePrevious(isOpen);

  const onClick = useCallback(
    (e) => {
      const elm = node.current;
      if (!!e.target) {
        if (elm.contains(e.target)) {
          return;
        }

        //check if the target Id is a dropdown item.
        //The list of dropdown items is moved to document.body.
        //On this way, the list appears always on top of modal,
        //but it isn't contained in the modal html nodes.
        const targetId = e.target.id;
        if (
          (!!targetId &&
            targetId.indexOf('react-select-') === 0 && //This is a real dropdown item name: 'react-select-6-option-0';
            targetId.indexOf('-option-') > 13) || //13 is the lenght of 'react-select-'
          e.target.className.includes('react-select-element-') // Overlay of dropdown elements
        ) {
          return;
        }
      }
      onClose();
    },
    [node, onClose],
  );

  useEffect(() => {
    if (isOpen !== previousIsOpen) {
      if (isOpen) {
        onOpen();
      }
    }
  }, [isOpen, previousIsOpen, onOpen]);

  return (
    <div
      css={styles.modal}
      style={{ zIndex: isOpen ? zIndex : -1 }}
      className={classNames({ 'is-open': isOpen })}
      {...(!blocker && { onClick })}
    >
      <div ref={node} style={{ width: !!width ? width : null }}>
        <div css={styles.top}>
          {!!title && <div css={styles.top.title}>{title}</div>}
          {showButton && buttons}
        </div>
        <div css={styles.body}>{isOpen && children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  blocker: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  isOpen: false,
  blocker: false,
  zIndex: 1000,
  onOpen: () => {},
};

export default Modal;
