import produce from 'immer';
import { put, select, takeLatest } from 'redux-saga/effects';

import { COMMON_PATCH_OPERATION } from '../../../common/redux/constants';
import { saveImages } from '../../../../common/code/image.repository';
import {
  selectCurrentId,
  selectCurrentProtocolTotalImages,
  selectIsCurrentProtocolOffline,
} from '../../redux/selectors';
import {
  FILL_STEP_SAVE_IMAGE,
  FILL_STEP_SAVE_IMAGE_ERROR,
  FILL_STEP_CLOSE_IMAGE_SKETCHER_MODAL,
} from './constants';
import { selectSelectedIds } from './selectors';
import { actions as toastrActions } from 'react-redux-toastr';
import { i18n } from '../../../../common/i18n-loader';

export const IMAGES_TOASTR_FIRST_THRESHOLD = 30;
export const IMAGES_TOASTR_SECOND_THRESHOLD = 40;
export const IMAGES_TOASTR_THIRD_THRESHOLD = 45;
export const MAX_IMAGES_PER_PROTOCOL = 50;

export function fillStepSaveImage(image) {
  return {
    type: FILL_STEP_SAVE_IMAGE,
    payload: image,
  };
}

function* doSaveImage({ payload }) {
  const protocolId = yield select(selectCurrentId);
  const selectedIds = yield select(selectSelectedIds);
  const isCurrentProtocolOffline = yield select(selectIsCurrentProtocolOffline);
  const initialImagesTotal = yield select(selectCurrentProtocolTotalImages);

  const expression =
    // prettier-ignore
    `componentsInput/[${selectedIds.componentId}]/elements/[${selectedIds.elementId}]/images/[${payload.id}]`;

  try {
    // If, for some unknown reason, a request is done when the images have reached
    // the allowed limit, we "silently" stop the action.
    if (initialImagesTotal >= MAX_IMAGES_PER_PROTOCOL) {
      console.error('Images limit reached!');
      return;
    }

    yield saveImages([payload], isCurrentProtocolOffline);

    yield put({
      type: FILL_STEP_CLOSE_IMAGE_SKETCHER_MODAL,
    });

    yield put({
      type: COMMON_PATCH_OPERATION,
      payload: {
        protocolId,
        expression,
        value: { ...payload, data: null },
        isImage: true,
      },
    });

    const newImagesTotal = initialImagesTotal + 1;

    if (
      newImagesTotal === IMAGES_TOASTR_FIRST_THRESHOLD ||
      newImagesTotal === IMAGES_TOASTR_SECOND_THRESHOLD ||
      newImagesTotal === IMAGES_TOASTR_THIRD_THRESHOLD
    ) {
      yield put(
        toastrActions.add({
          type: 'info',
          message: i18n._('FILL-STEP.IMAGE-ADDED-THRESHOLD', {
            current: newImagesTotal,
            max: MAX_IMAGES_PER_PROTOCOL,
          }),
        }),
      );
    } else if (newImagesTotal >= MAX_IMAGES_PER_PROTOCOL) {
      yield put(
        toastrActions.add({
          type: 'warning',
          message: i18n._('FILL-STEP.IMAGE-LIMIT-REACHED', {
            current: newImagesTotal,
            max: MAX_IMAGES_PER_PROTOCOL,
          }),
        }),
      );
    }
  } catch (error) {
    yield put({
      type: FILL_STEP_SAVE_IMAGE_ERROR,
      payload: error.message,
    });
  }
}

export function* switchSaveImage() {
  yield takeLatest(FILL_STEP_SAVE_IMAGE, doSaveImage);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILL_STEP_SAVE_IMAGE:
        draft.ui.modal.imageSketcher = false;
        draft.currentEditImage = null;
        break;
      default:
        return state;
    }
  });
