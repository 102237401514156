import { push } from 'connected-react-router';
import produce from 'immer';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { commonGetProtocols } from '../../common/redux/actions';
import { post } from '../../common/services/api';
import {
  HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE,
  HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_ERROR,
  HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_SUCCESS,
} from './constants';
import { homeRoutes } from '../routes';

export function homeCreateProtocolWithREMReference(data) {
  return {
    type: HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE,
    payload: data,
  };
}

function* doHomeCreateProtocolWithREMReference({ payload }) {
  try {
    yield call(post, 'protocol/withremreference', payload);

    yield put({
      type: HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_ERROR,
      payload: error.message,
    });
  }
}

function* doGoToHomepage() {
  yield put(push(homeRoutes.home));
  yield put(commonGetProtocols());
}

export function* switchCreateProtocolWithREMReference() {
  yield takeEvery(HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE, doHomeCreateProtocolWithREMReference);
  yield takeLatest(HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_SUCCESS, doGoToHomepage);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE:
        draft.ui.busy.createProtocolWithREMReference = true;
        break;
      case HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_SUCCESS:
        draft.ui.busy.createProtocolWithREMReference = false;
        draft.ui.modal.protocolWithREMReference = false;
        break;
      case HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_ERROR:
        draft.ui.busy.createProtocolWithREMReference = false;
        break;
      default:
        return state;
    }
  });
