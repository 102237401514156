import { reducer as checkIsAuth } from './checkIsAuth';
import { reducer as closeConfirmationModal } from './closeConfirmationModal';
import { reducer as closeInformationModal } from './closeInformationModal';
import { reducer as closeNotImplementedModal } from './closeNotImplementedModal';
import { reducer as deleteOperation } from './deleteOperation';
import { reducer as deleteProtocol } from './deleteProtocol';
import { reducer as enqueueOperation } from './enqueueOperation';
import { reducer as flushQueues } from './flushQueues';
import { reducer as getProtocols } from './getProtocols';
import initialState from './initialState';
import { reducer as injectProtocol } from './injectProtocol';
import { reducer as isOffline } from './isOffline';
import { reducer as isOnline } from './isOnline';
import { reducer as login } from './login';
import { reducer as logout } from './logout';
import { reducer as openConfirmationModal } from './openConfirmationModal';
import { reducer as openInformationModal } from './openInformationModal';
import { reducer as openNotImplementedModal } from './openNotImplementedModal';
import { reducer as patchOperation } from './patchOperation';
import { reducer as setLanguage } from './setLanguage';
import { reducer as setVersion } from './setVersion';
import { reducer as getPdfFromProtocol } from './commonGetPdfsFromProtocol';
import { reducer as markAsOffline } from './markAsOffline';
import { reducer as syncOffline } from './syncOffline';
import { reducer as getLeavingAgreement } from './getLeavingAgreement';
import { reducer as getTenancyAgreement } from './getTenancyAgreement';
import { reducer as setCurrentSigner } from './setCurrentSigner';
import { reducer as abortAndCloseSignPage } from './abortAndCloseSignPage';
import { reducer as errorSign } from './errorSign';
import { reducer as sentryError } from './sentryConf';
import { reducer as keycloak } from './keycloak';
import { reducer as openLoginModal} from "./openLoginModal";
import { reducer as closeLoginModal} from "./closeLoginModal";
import { COMMON_LOGIN_SUCCESS } from "./constants";

const reducers = [
  setLanguage,
  setVersion,
  isOnline,
  isOffline,
  login,
  checkIsAuth,
  logout,
  openNotImplementedModal,
  closeNotImplementedModal,
  deleteProtocol,
  getProtocols,
  enqueueOperation,
  flushQueues,
  injectProtocol,
  openConfirmationModal,
  closeConfirmationModal,
  openInformationModal,
  closeInformationModal,
  patchOperation,
  deleteOperation,
  getPdfFromProtocol,
  markAsOffline,
  syncOffline,
  getLeavingAgreement,
  getTenancyAgreement,
  setCurrentSigner,
  abortAndCloseSignPage,
  errorSign,
  sentryError,
  keycloak,
  openLoginModal,
  closeLoginModal,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    case COMMON_LOGIN_SUCCESS:
      state.ui.modal.login = false;
      newState = state;
      break;
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
