/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { ToggleButton } from '../../common/ToggleSwitch';

export const FormToggleField = (props) => {
  const { name, setFieldValue, onChange, value } = props;

  const handleChange = useCallback(
    (value) => {
      setFieldValue(name, value);
      onChange({ currentTarget: { value } });
    },
    [name, setFieldValue, onChange],
  );

  const isDefaultChecked =
    typeof value === 'boolean' ? value : value.toLocaleLowerCase() === 'true';

  return <ToggleButton defaultChecked={isDefaultChecked} onChange={handleChange} />;
};
