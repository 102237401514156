/** @jsxImportSource @emotion/react */
import { colors } from '../../common/theme/colors';

export const styles = {
  wrapper: {
    background: colors.dark,
    width: '100%',
    height: '100%',
    padding: '64px 8vw',
  },
  wrapperFullWidth: {
    padding: '12px 0',
  },
  container: (containerColors) => ({
    fontFamily: '"Rubik", sans-serif !important',
    padding: '20px 24px 24px',
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: '32px',
    border: `2px solid ${containerColors.color}`,
    boxShadow: `0 0 0 6px  ${containerColors.transparent}`,
    background: colors.darker,
    borderRadius: '16px',
    color: '#fff',
  }),
  headerContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: '16px',
  },
  title: {
    fontSize: '32px',
  },
  subtitle: {
    fontSize: '18px',
    color: colors.grey80,
    maxWidth: '50ch',
  },
  buttonContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'min-content',
    gridColumnGap: '16px',
  },
  button: {
    borderRadius: '4px',
    padding: '8px 16px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    whiteSpace: 'nowrap',

    border: '2px solid transparent',
    color: '#fff',
    background: '#000',
  },
  buttonPrimary: {
    borderColor: colors.brand,
    background: 'rgba(49, 175, 225, 0.16)',
    color: '#fff',
  },
  buttonSecondary: {
    borderColor: 'transparent',
    background: 'transparent',
    color: colors.brand,
  },
};

const types = {
  info: {
    color: colors.brand,
    transparent: 'rgba(49, 175, 225, 0.16)',
  },
  error: {
    color: colors.errors,
    transparent: 'rgba(252, 84, 83, 0.16)',
  },
};

export const ErrorBoundaryTemplate = (props) => {
  const {
    title = 'Something went unexpectedly wrong.',
    subtitle = 'We will work at our best to fix this error.',
    showReportFeedbackButton = true,
    showGoBackButton = true,
    fullWidth = false,
    onReportFeedback,
    type = 'error',
  } = props;

  return (
    <div css={{ ...styles.wrapper, ...(!!fullWidth && styles.wrapperAdaptiveWidth) }}>
      <div css={styles.container(types[type])}>
        <div css={styles.headerContainer}>
          <div css={styles.title}>{title}</div>
          <div css={styles.subtitle}>{subtitle}</div>
          {!!showReportFeedbackButton && (
            <div css={styles.subtitle}>
              Please use the 'Report feedback' button to help us finding the problem.
            </div>
          )}
        </div>
        {!!showReportFeedbackButton ||
          (!!showGoBackButton && (
            <div css={styles.buttonContainer}>
              {!!showReportFeedbackButton && (
                <button
                  css={{ ...styles.button, ...styles.buttonPrimary }}
                  onClick={onReportFeedback}
                >
                  Report feedback
                </button>
              )}
              {!!showGoBackButton && (
                <a css={{ ...styles.button, ...styles.buttonSecondary }} href="/">
                  Go back home
                </a>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
