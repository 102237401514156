import produce from 'immer';
import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from '../services/api';
import {
  COMMON_GET_LEAVING_AGREEMENT,
  COMMON_GET_LEAVING_AGREEMENT_ERROR,
  COMMON_GET_LEAVING_AGREEMENT_SUCCESS,
} from './constants';

export function commonGetLeavingAgreement(protocolId) {
  return {
    type: COMMON_GET_LEAVING_AGREEMENT,
    payload: protocolId,
  };
}

function* doGetLeavingAgreement({ payload }) {
  try {
    const leavingAgreement = yield call(get, `leavingagreement/${payload}`);

    yield put({
      type: COMMON_GET_LEAVING_AGREEMENT_SUCCESS,
      payload: leavingAgreement,
    });
  } catch (error) {
    yield put({
      type: COMMON_GET_LEAVING_AGREEMENT_ERROR,
      payload: error.message,
    });
  }
}

export function* switchGetLeavingAgreement() {
  yield takeLatest(COMMON_GET_LEAVING_AGREEMENT, doGetLeavingAgreement);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_GET_LEAVING_AGREEMENT:
        draft.ui.busy.getProtocols = true;
        break;
      case COMMON_GET_LEAVING_AGREEMENT_SUCCESS:
        draft.ui.busy.getProtocols = false;
        draft.leavingAgreements.length = 0;
        draft.leavingAgreements.push(action.payload);
        break;
      case COMMON_GET_LEAVING_AGREEMENT_ERROR:
        draft.ui.busy.getProtocols = false;
        break;
      default:
        return state;
    }
  });
