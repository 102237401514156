import { LOCATION_CHANGE } from 'connected-react-router';
import { equals } from 'ramda';
import { put, select, takeLatest } from 'redux-saga/effects';

import { extractNames } from '../../../../common/code/extractNames';
import { commonPatchOperation } from '../../../common/redux/actions';
import {
  selectCurrentProtocol,
  selectIndexedBasedataInput,
  selectProtocolIdRoute,
} from '../../redux/selectors';

function* doUpdatePersonList() {
  const route = yield select(selectProtocolIdRoute);

  if (!!route) {
    if (!!route.params.id && route.params.step && route.params.step === 'sign-step') {
      const protocol = yield select(selectCurrentProtocol);

      if (!protocol) {
        return;
      }

      const indexedBasedataInput = yield select(selectIndexedBasedataInput);
      const oldRentalPerson = indexedBasedataInput['oldRentalPerson'];
      const oldRentalPerson2 = indexedBasedataInput['oldRentalPerson_2'];
      const newRentalPerson = indexedBasedataInput['newRentalPerson'];
      const newRentalPerson2 = indexedBasedataInput['newRentalPerson_2'];

      const oldTenant = [];
      const newTenant = [];

      if (!!oldRentalPerson) {
        const oldRentalName = extractNames(
          oldRentalPerson.fields,
          'VormieterVorname',
          'VormieterName',
        );

        oldTenant.push(oldRentalName);
      }

      if (!!oldRentalPerson2) {
        const oldRentalName2 = extractNames(
          oldRentalPerson2.fields,
          'VormieterVorname',
          'VormieterName',
        );

        oldTenant.push(oldRentalName2);
      }

      if (!!newRentalPerson) {
        const newRentalName = extractNames(
          newRentalPerson.fields,
          'NachmieterVorname',
          'NachmieterName',
        );

        newTenant.push(newRentalName);
      }

      if (!!newRentalPerson2) {
        const newRentalName2 = extractNames(
          newRentalPerson2.fields,
          'NachmieterVorname',
          'NachmieterName',
        );

        newTenant.push(newRentalName2);
      }

      if (!equals(protocol.input.signatures.oldTenant.personList, oldTenant)) {
        yield put(
          commonPatchOperation(protocol.id, `signatures/oldTenant`, { personList: oldTenant }),
        );
      }
      if (!equals(protocol.input.signatures.newTenant.personList, newTenant)) {
        yield put(
          commonPatchOperation(protocol.id, `signatures/newTenant`, { personList: newTenant }),
        );
      }
    }
  }
}

export function* switchUpdatePersonList() {
  yield takeLatest(LOCATION_CHANGE, doUpdatePersonList);
}
