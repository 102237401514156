import { put, select, takeEvery } from 'redux-saga/effects';
import { MAX_IMAGES_PER_PROTOCOL } from '../../acceptance-protocol/fill-step/redux/fillStepSaveImage';
import {
  selectCurrentId,
  selectCurrentProtocol,
  selectCurrentProtocolTotalImages,
} from '../../acceptance-protocol/redux/selectors';
import {
  COMMON_DUPLICATE_COMPONENT,
  COMMON_DUPLICATE_COMPONENT_ERROR,
  COMMON_DUPLICATE_COMPONENT_SUCCESS,
} from './constants';
import { commonDeleteOperation } from './deleteOperation';
import { commonPatchOperation } from './patchOperation';
import { performTakeover, getPreviousComponentInputsToTakeover } from './performTakeover';
import { actions as toastrActions } from 'react-redux-toastr';
import { i18n } from '../../../common/i18n-loader';

export function commonDuplicateComponent(componentId) {
  return { type: COMMON_DUPLICATE_COMPONENT, payload: componentId };
}

function* doDuplicateComponent({ payload }) {
  const protocolId = yield select(selectCurrentId);

  if (!!protocolId) {
    try {
      const protocol = yield select(selectCurrentProtocol);

      const componentInputsToTakeover = getPreviousComponentInputsToTakeover(protocol, [payload]);

      const duplicatedComponents = performTakeover(protocol, componentInputsToTakeover);

      for (const duplicatedComponent of duplicatedComponents) {
        yield put(
          commonPatchOperation(
            protocolId,
            `componentsInput/[${duplicatedComponent.id}]`,
            duplicatedComponent,
          ),
        );

        yield put(
          commonDeleteOperation(protocolId, `previousComponentsInput/[${duplicatedComponent.id}]`),
        );
      }

      const imagesTotal = yield select(selectCurrentProtocolTotalImages);

      if (imagesTotal >= MAX_IMAGES_PER_PROTOCOL) {
        yield put(
          toastrActions.add({
            type: 'warning',
            message: i18n._('FILL-STEP.IMAGE-LIMIT-REACHED-TAKEOVER', {
              max: MAX_IMAGES_PER_PROTOCOL,
            }),
          }),
        );
      }

      yield put({
        type: COMMON_DUPLICATE_COMPONENT_SUCCESS,
      });
    } catch (error) {
      yield put({
        type: COMMON_DUPLICATE_COMPONENT_ERROR,
        payload: error.message,
      });
    }
  }
}

export function* switchDuplicateComponent() {
  yield takeEvery(COMMON_DUPLICATE_COMPONENT, doDuplicateComponent);
}
