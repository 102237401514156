import { LOCATION_CHANGE } from 'connected-react-router';
import { put, select, takeLatest } from 'redux-saga/effects';

import { extractNames } from '../../../../common/code/extractNames';
import { commonPatchOperation, commonDeleteOperation } from '../../../common/redux/actions';
import {
  selectCurrentProtocol,
  selectIndexedBasedataInput,
  selectProtocolIdRoute,
} from '../../redux/selectors';
import { getByProperty } from '../../../common/Functions';

function checkForUpdate(oldValue, value, fieldname) {
  const emailsValue = !!value ? extractNames(value.fields, fieldname) : null;
  return {
    isUpdated: (!oldValue && !emailsValue) || (!!oldValue && oldValue.emails === emailsValue),
    value: emailsValue,
  };
}

function* doUpdateEmails() {
  const route = yield select(selectProtocolIdRoute);

  if (!!route) {
    if (!!route.params.id && route.params.step && route.params.step === 'send-step') {
      const protocol = yield select(selectCurrentProtocol);

      if (!protocol) {
        return;
      }

      const indexedBasedataInput = yield select(selectIndexedBasedataInput);
      const oldRentalPerson = indexedBasedataInput['oldRentalPerson'];
      const oldRentalPerson2 = indexedBasedataInput['oldRentalPerson_2'];
      const newRentalPerson = indexedBasedataInput['newRentalPerson'];
      const newRentalPerson2 = indexedBasedataInput['newRentalPerson_2'];

      const oldTenant = getByProperty('type', 'oldTenant')(protocol.input.sendStep);
      const oldTenant2 = getByProperty('type', 'oldTenant_2')(protocol.input.sendStep);
      const newTenant = getByProperty('type', 'newTenant')(protocol.input.sendStep);
      const newTenant2 = getByProperty('type', 'newTenant_2')(protocol.input.sendStep);

      const oldTenantResult = checkForUpdate(oldTenant, oldRentalPerson, 'VormieterEmail');
      if (!oldTenantResult.isUpdated) {
        yield put(
          commonPatchOperation(protocol.id, 'sendStep/[oldTenant]', {
            emails: oldTenantResult.value,
            ...(!oldTenantResult.value && { send: false, sendAroov: false }),
          }),
        );
      }

      const oldTenant2Result = checkForUpdate(oldTenant2, oldRentalPerson2, 'VormieterEmail');
      if (!oldTenant2Result.isUpdated) {
        yield put(
          !!oldTenant2Result.value
            ? commonPatchOperation(protocol.id, 'sendStep/[oldTenant_2]', {
                emails: oldTenant2Result.value,
              })
            : commonDeleteOperation(protocol.id, 'sendStep/[oldTenant_2]'),
        );
      }

      const newTenantResult = checkForUpdate(newTenant, newRentalPerson, 'NachmieterEmail');
      if (!newTenantResult.isUpdated) {
        yield put(
          commonPatchOperation(protocol.id, 'sendStep/[newTenant]', {
            emails: newTenantResult.value,
            ...(!newTenantResult.value && { send: false, sendAroov: false }),
          }),
        );
      }

      const newTenant2Result = checkForUpdate(newTenant2, newRentalPerson2, 'NachmieterEmail');
      if (!newTenant2Result.isUpdated) {
        yield put(
          !!newTenant2Result.value
            ? commonPatchOperation(protocol.id, 'sendStep/[newTenant_2]', {
                emails: newTenant2Result.value,
              })
            : commonDeleteOperation(protocol.id, 'sendStep/[newTenant_2]'),
        );
      }
    }
  }
}

export function* switchUpdateEmails() {
  yield takeLatest(LOCATION_CHANGE, doUpdateEmails);
}
