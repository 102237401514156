import React from 'react';

export const InArrowIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 18"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <path d="M14,18a.84.84,0,0,1-.38-.08A1,1,0,0,1,13,17V13H0V11H14a1,1,0,0,1,1,1v2.59L20.59,9,15,3.41V6a1,1,0,0,1-1,1H0V5H13V1a1,1,0,0,1,.62-.92,1,1,0,0,1,1.09.21l8,8a1,1,0,0,1,0,1.42l-8,8A1,1,0,0,1,14,18Z" />
  </svg>
);
