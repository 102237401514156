/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import LoginModal from "./LoginModal";
import { LOGIN_WORKFLOW_EXPIRED_TOKEN } from "./redux/loginWorkflowConstants";
import { useEffect } from "react";
import { useKeyCloak } from "../../common/KeyCloakContext";

const LoginWrapper = (props) => {
  const {
    children, 
    client,
    isLoginModalOpen,
    isLoginModalBusy,
    loginInfo,
    loginWorkflow,
    isKeycloakActive,
    commonLogin,
    commonCloseLoginModal,
    commonLogout,
    isOnline,
  } = props;

  const { isKeyCloakReady, keyCloakInstance, authenticated } = useKeyCloak() || {};
  
  useEffect(() => {
    if (((isLoginModalOpen && loginWorkflow === LOGIN_WORKFLOW_EXPIRED_TOKEN) || !authenticated) && isKeyCloakReady && keyCloakInstance && isOnline){
      keyCloakInstance.login();
    }
  }, [isLoginModalOpen, loginWorkflow, authenticated, isKeyCloakReady, keyCloakInstance, isOnline]);

  const handleLogin = (username, password, client) => {
    commonLogin(loginWorkflow, username, password, client);
  };

  const handleLoginModalClose = () => {
    if (loginWorkflow === LOGIN_WORKFLOW_EXPIRED_TOKEN) {
      commonLogout();
    }

    commonCloseLoginModal();
  };

  return (
    <>
      <main>
        {children}
      </main>

      {!isKeycloakActive && <LoginModal
        isOpen={isLoginModalOpen}
        onClose={handleLoginModalClose}
        onLogin={handleLogin}
        isBusy={isLoginModalBusy}
        loginInfo={loginInfo}
        client={client}
        loginWorkflow={loginWorkflow}
      />}
    </>
  );
};

LoginWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  client: PropTypes.string.isRequired,
  isLoginModalOpen:PropTypes.bool.isRequired,
  isLoginModalBusy:PropTypes.bool.isRequired,
  loginInfo: PropTypes.object.isRequired,
  loginWorkflow: PropTypes.number,
  isKeycloakActive: PropTypes.bool.isRequired,
  commonLogin: PropTypes.func.isRequired,
  commonCloseLoginModal: PropTypes.func.isRequired,
  commonLogout: PropTypes.func.isRequired,
  isOnline: PropTypes.bool.isRequired,
};

export default LoginWrapper;
