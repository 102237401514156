/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { colors } from '../../../common/theme/colors';
import { variables } from '../../../common/theme/variables';
import { FormDateField } from './FormDateField';
import { FormEmailField } from './FormEmailField';
import { FormStringField } from './FormStringField';
import { getLocalizedLabel } from './Functions';
import { useDebouncedWithStateCallback } from '../../../common/code/useDebouncedWithStateCallback';
import { i18n } from '../../../common/i18n-loader';
import { FormToggleField } from './FormToggleField';
import { FormSelectField } from './FormSelectField';

// width: `${isFullRow ? '100%' : column === 0 ? '60%' : '40%'}`,
const styles = {
  rowContainer: (siblingsLength) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `3fr ${siblingsLength > 0 ? 'repeat(' + siblingsLength + ', 1fr)' : ''}`,
  }),
  fieldContainer: (isSibling, row, fixedLabel, hasErrors) => ({
    backgroundColor: `${row % 2 === 0 ? colors.white : colors.lightGrey}`,
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: `min-content auto ${hasErrors ? 'min-content' : ''}`,
    gridTemplateAreas: `"label" "field" ${hasErrors ? '"error"' : ''}`,
    gridGap: '8px',
    alignItems: 'center',
    padding: '12px 16px',

    [variables.breakpoints.xl]: {
      gridTemplateColumns: `${fixedLabel ? '184px' : 'minmax(auto,2fr)'} minmax(${
        isSibling ? '64px' : 'auto'
      }, ${isSibling ? '1.5fr' : '4fr'})`,
      gridTemplateRows: `auto ${hasErrors ? 'min-content' : ''}`,
      gridTemplateAreas: `"label field" ${hasErrors ? '"error error"' : ''}`,
      padding: '12px',
    },

    borderColor: colors.lighterGrey,
    borderWidth: '0 1px 1px 1px',
    borderStyle: 'solid',
  }),
  label: {
    gridArea: 'label',
    alignItems: 'center',

    '& > label': {
      fontWeight: 'bold',
    },
  },
  field: {
    gridArea: 'field',
    alignItems: 'center',

    '& > input': {
      width: '100%',
      marginBottom: '0',
    },

    '& > input.has-error': {
      border: `2px solid ${colors.error}`,
    },

    '& > div > input.has-error': {
      border: `2px solid ${colors.error}`,
    },
  },
  error: {
    gridArea: 'error',
    alignItems: 'center',

    '& > div': {
      width: '100%',
      wordBreak: 'break-all',
      fontSize: '14px',
      color: colors.error,
      fontWeight: 'bold',
      textAlign: 'right',
    },
  },
};

const types = {
  date: ({ name, label, setFieldValue, setFieldTouched, onChange, value, errors }) => (
    <FormDateField
      name={name}
      label={label}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      onChange={onChange}
      value={value}
      errors={errors}
    />
  ),
  email: ({ name, setFieldValue, setFieldTouched, onChange, value, errors }) => (
    <FormEmailField
      name={name}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      onChange={onChange}
      value={value}
      errors={errors}
    />
  ),
  string: ({ name, onChange, setFieldValue, setFieldTouched, value, errors }) => (
    <FormStringField
      name={name}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      onChange={onChange}
      value={value}
      errors={errors}
    />
  ),
  toggle: ({ name, onChange, setFieldValue, value }) => (
    <FormToggleField name={name} setFieldValue={setFieldValue} onChange={onChange} value={value} />
  ),
  select: ({ name, onChange, setFieldValue, setFieldTouched, value, field, language }) => (
    <FormSelectField
      name={name}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      onChange={onChange}
      value={value}
      options={field.fieldOptions}
      language={language}
    />
  ),
};

const fieldSelector = ({
  field,
  name,
  label,
  setFieldValue,
  setFieldTouched,
  onChange,
  value,
  touched,
  errors,
  language,
}) => {
  let selectedField = !!field.fieldType && types[field.fieldType.toLowerCase()];

  if (!selectedField) {
    selectedField = types['string'];
  }

  return selectedField({
    name,
    label,
    setFieldValue,
    setFieldTouched,
    onChange,
    value,
    touched,
    errors,
    field,
    language,
  });
};

function mainRender(hasSiblings, hasSomeSiblings, props, onModify) {
  const { index, field, language, values, errors, touched, setFieldValue, setFieldTouched } = props;
  const name = escape(field.fieldname);

  return (
    <Field
      name={name}
      field={field}
      row={index}
      column={0}
      isFullRow={!hasSiblings}
      isSibling={false}
      // WORKAROUND: We set a fixed width to the label if there is any sibling in the entire form.
      fixedLabel={hasSomeSiblings}
      language={language}
      value={values[name]}
      errors={errors[name]}
      touched={touched[name]}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      onModify={onModify}
    />
  );
}

function siblingRender(sibling, indexSibling, hasSiblings, props, onModify) {
  const { index, field, language, values, errors, touched, setFieldValue, setFieldTouched } = props;

  const name = `${escape(field.fieldname)}\\${escape(sibling.fieldname)}`;

  return (
    <Field
      key={name}
      name={name}
      field={sibling}
      row={index}
      column={indexSibling + 1}
      isFullRow={!hasSiblings}
      isSibling={true}
      fixedLabel={false}
      language={language}
      value={values[name]}
      errors={errors[name]}
      touched={touched[name]}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      onModify={onModify}
    />
  );
}

export const FormField = (props) => {
  const { id, field, hasSomeSiblings } = props;

  const siblings = !!field.siblings ? field.siblings : [];

  const { onModifyField, onModifySibling } = props;

  const handleModify = useCallback(
    (name, value) => {
      const names = name.split('\\');

      if (names.length > 1) {
        onModifySibling(id, unescape(names[0]), unescape(names[1]), value);
      } else {
        onModifyField(id, unescape(names[0]), value);
      }
    },
    [id, onModifyField, onModifySibling],
  );

  return (
    <div css={styles.rowContainer(siblings.length)}>
      {mainRender(siblings.length > 0, hasSomeSiblings, props, handleModify)}
      {siblings.map((sibling, siblingIndex) =>
        siblingRender(sibling, siblingIndex, siblings.length >= 1, props, handleModify),
      )}
    </div>
  );
};

FormField.propTypes = {
  index: PropTypes.number.isRequired,
  field: PropTypes.object.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  transformation: PropTypes.func,
  language: PropTypes.string.isRequired,
  onModifyField: PropTypes.func.isRequired,
  onModifySibling: PropTypes.func.isRequired,
};

const Field = (props) => {
  const {
    name,
    field,
    row,
    isSibling,
    fixedLabel,
    language,

    onModify,

    value,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = props;

  const onChange = useCallback(
    (value) => {
      onModify(name, value);
    },
    [name, onModify],
  );

  const [stateValue, handleChange] = useDebouncedWithStateCallback(onChange, value);

  const label = getLocalizedLabel(field, language);
  return (
    <div css={styles.fieldContainer(isSibling, row, fixedLabel, !!errors)}>
      <div css={styles.label}>
        <label>{label}</label>
      </div>
      <div css={styles.field}>
        {fieldSelector({
          field,
          name,
          label,
          setFieldValue,
          setFieldTouched,
          onChange: handleChange,
          value: stateValue,
          touched,
          errors,
          language,
        })}
      </div>
      {!!errors && (
        <div css={styles.error}>
          <div>{i18n._(errors)}</div>
        </div>
      )}
    </div>
  );
};
