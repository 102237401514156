import { put, select, takeEvery } from 'redux-saga/effects';
import { MAX_IMAGES_PER_PROTOCOL } from '../../acceptance-protocol/fill-step/redux/fillStepSaveImage';
import {
  selectCurrentId,
  selectCurrentProtocol,
  selectCurrentProtocolTotalImages,
  selectPreviousComponentsOrderInput,
} from '../../acceptance-protocol/redux/selectors';
import {
  COMMON_DUPLICATE_ALL_COMPONENTS,
  COMMON_DUPLICATE_ALL_COMPONENTS_ERROR,
  COMMON_DUPLICATE_ALL_COMPONENTS_SUCCESS,
} from './constants';
import { commonPatchOperation } from './patchOperation';
import { performTakeover } from './performTakeover';
import { actions as toastrActions } from 'react-redux-toastr';
import { i18n } from '../../../common/i18n-loader';

export function commonDuplicateAllComponents() {
  return { type: COMMON_DUPLICATE_ALL_COMPONENTS };
}

function* doDuplicateAllComponents() {
  const protocolId = yield select(selectCurrentId);

  if (!!protocolId) {
    try {
      const previousComponentsOrderInput = yield select(selectPreviousComponentsOrderInput);

      const protocol = yield select(selectCurrentProtocol);

      const duplicatedComponents = performTakeover(protocol);

      for (const duplicatedComponent of duplicatedComponents) {
        yield put(
          commonPatchOperation(
            protocolId,
            `componentsInput/[${duplicatedComponent.id}]`,
            duplicatedComponent,
          ),
        );
      }

      yield put(
        commonPatchOperation(protocolId, 'componentsOrderInput', previousComponentsOrderInput),
      );

      yield put(commonPatchOperation(protocolId, 'previousComponentsInput', null));

      yield put(commonPatchOperation(protocolId, 'previousComponentOrderInput', null));

      const imagesTotal = yield select(selectCurrentProtocolTotalImages);

      if (imagesTotal >= MAX_IMAGES_PER_PROTOCOL) {
        yield put(
          toastrActions.add({
            type: 'warning',
            message: i18n._('FILL-STEP.IMAGE-LIMIT-REACHED-TAKEOVER', {
              max: MAX_IMAGES_PER_PROTOCOL,
            }),
          }),
        );
      }

      yield put({
        type: COMMON_DUPLICATE_ALL_COMPONENTS_SUCCESS,
      });
    } catch (error) {
      yield put({
        type: COMMON_DUPLICATE_ALL_COMPONENTS_ERROR,
        payload: error.message,
      });
    }
  }
}

export function* switchDuplicateAllComponents() {
  yield takeEvery(COMMON_DUPLICATE_ALL_COMPONENTS, doDuplicateAllComponents);
}
