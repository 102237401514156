import produce from 'immer';
import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { find } from 'ramda';
import HttpStatus from 'http-status-codes';
import { i18n } from '../../../../common/i18n-loader';
import { askForConfirmation } from '../../../common/redux/openConfirmationModal';
import { post, deleteById, patch } from '../../../common/services/api';
import {
  selectCurrentId,
  selectCurrentHasQueue,
  selectCurrentLeavingAgreement,
  selectIsProtocolSynchronizationError,
  selectCurrentHasOnSiteWorkflow,
} from '../../redux/selectors';
import {
  SEND_STEP_COMPLETE_PROTOCOL,
  SEND_STEP_COMPLETE_PROTOCOL_CLOSED,
  SEND_STEP_COMPLETE_PROTOCOL_EMAIL_SENT,
  SEND_STEP_COMPLETE_PROTOCOL_ERROR,
  SEND_STEP_COMPLETE_PROTOCOL_PDFS_GENERATED,
  SEND_STEP_COMPLETE_PROTOCOL_SYNCHRONIZED,
  SEND_STEP_COMPLETE_PROTOCOL_START,
  SEND_STEP_COMPLETE_PROTOCOL_END,
  SEND_STEP_COMPLETE_PROTOCOL_SUCCESS,
  SEND_STEP_CLOSE_PROGRESS_MODAL,
  SEND_STEP_COMPLETE_PROTOCOL_ONSITE_MESSAGE_SYNC,
  SEND_STEP_COMPLETE_PROTOCOL_ONSITE_MESSAGE_SYNC_ERROR,
  PROGRESS_STATUS_ON_PROGRESS,
  PROGRESS_STATUS_INITIAL,
  PROGRESS_STATUS_ENDED,
  PROGRESS_STATUS_ERROR,
  PROGRESS_STATUS_COMPLETED,
  SEND_STEP_COMPLETE_PROTOCOL_ONSITE_MESSAGE_SYNC__AUTH_ERROR,
  PROGRESS_STATUS_AUTH_ERROR,
} from './constants';
import {
  COMMON_SYNC_OFFLINE,
  COMMON_UNMARK_ERROR,
  COMMON_DELETE_PROTOCOL_CONFIRM,
} from '../../../common/redux/constants';

export function sendStepCompleteProtocol() {
  return {
    type: SEND_STEP_COMPLETE_PROTOCOL,
  };
}

export function sendStepCloseProgressModal() {
  return {
    type: SEND_STEP_CLOSE_PROGRESS_MODAL,
  };
}

function* doCompleteProtocol() {
  const confirmed = yield call(askForConfirmation, {
    title: i18n._('SEND-STEP.COMPLETE-PROTOCOL.TITLE'),
    message: i18n._('SEND-STEP.COMPLETE-PROTOCOL.MESSAGE'),
  });

  if (!confirmed) {
    return;
  }

  const protocolId = yield select(selectCurrentId);

  try {
    yield put({
      type: SEND_STEP_COMPLETE_PROTOCOL_START,
    });

    const protocolHasSynchronizationError = yield select(selectIsProtocolSynchronizationError);

    if (protocolHasSynchronizationError) {
      yield put({
        type: COMMON_UNMARK_ERROR,
        payload: protocolId,
      });
    }

    yield put({
      type: COMMON_SYNC_OFFLINE,
      payload: protocolId,
    });

    // This check if the protocol exists and is not completed
    yield call(patch, 'protocol', { protocolId, operations: [] });

    let attempt = 0;

    while (yield select(selectCurrentHasQueue)) {
      yield delay(500);
      if (attempt++ > 100) {
        throw new Error(i18n._('MESSAGES.QUEUE-FLUSH-ERROR'));
      }
    }

    yield put({
      type: SEND_STEP_COMPLETE_PROTOCOL_SYNCHRONIZED,
    });

    yield call(post, 'pdf', { protocolId });

    yield put({
      type: SEND_STEP_COMPLETE_PROTOCOL_PDFS_GENERATED,
    });

    yield call(post, 'email', { protocolId });

    const hasOnSiteWorkflow = yield select(selectCurrentHasOnSiteWorkflow);

    yield put({
      type: SEND_STEP_COMPLETE_PROTOCOL_EMAIL_SENT,
    });

    yield call(post, 'protocol/completeprotocol', { protocolId });

    const leavingAgreement = yield select(selectCurrentLeavingAgreement);

    if (!!leavingAgreement && !leavingAgreement.isSkipped) {
      yield call(deleteById, 'leavingagreement', leavingAgreement.id);
    }

    yield put({
      type: SEND_STEP_COMPLETE_PROTOCOL_CLOSED,
      payload: hasOnSiteWorkflow,
    });

    if (hasOnSiteWorkflow) {
      try {
        yield call(post, 'onSite', { protocolId });

        yield put({
          type: SEND_STEP_COMPLETE_PROTOCOL_ONSITE_MESSAGE_SYNC,
        });
      } catch (ex) {
        if (ex.status === HttpStatus.BAD_REQUEST && ex.type === 'ONSITE-MESSAGE-SYNC-AUTH-ERROR') {
          yield put({
            type: SEND_STEP_COMPLETE_PROTOCOL_ONSITE_MESSAGE_SYNC__AUTH_ERROR,
          });
        } else {
          yield put({
            type: SEND_STEP_COMPLETE_PROTOCOL_ONSITE_MESSAGE_SYNC_ERROR,
          });
        }
      }
    }

    yield put({
      type: SEND_STEP_COMPLETE_PROTOCOL_SUCCESS,
      payload: protocolId,
    });
  } catch (error) {
    if (
      error.status === HttpStatus.BAD_REQUEST &&
      !!find((x) => error.type, ['NotFoundError', 'AlreadyCompletedError'])
    ) {
      yield put({
        type: COMMON_DELETE_PROTOCOL_CONFIRM,
        payload: { error: error.message, protocolId },
      });
      yield put({
        type: SEND_STEP_COMPLETE_PROTOCOL_END,
      });
    } else {
      yield put({
        type: SEND_STEP_COMPLETE_PROTOCOL_ERROR,
        payload: error.message,
      });
    }
  }
}

export function* switchCompleteProtocol() {
  yield takeLatest(SEND_STEP_COMPLETE_PROTOCOL, doCompleteProtocol);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SEND_STEP_CLOSE_PROGRESS_MODAL:
        draft.ui.modal.progress = false;
        break;
      case SEND_STEP_COMPLETE_PROTOCOL_ERROR:
      case SEND_STEP_COMPLETE_PROTOCOL_END:
        draft.progress.sync = PROGRESS_STATUS_ENDED;
        draft.progress.pdfs = PROGRESS_STATUS_ENDED;
        draft.progress.emails = PROGRESS_STATUS_ENDED;
        draft.progress.closed = PROGRESS_STATUS_ENDED;
        draft.progress.onSite = PROGRESS_STATUS_ENDED;
        break;
      case SEND_STEP_COMPLETE_PROTOCOL_START:
        draft.ui.modal.progress = true;
        draft.progress.sync = PROGRESS_STATUS_ON_PROGRESS;
        draft.progress.pdfs = PROGRESS_STATUS_INITIAL;
        draft.progress.emails = PROGRESS_STATUS_INITIAL;
        draft.progress.closed = PROGRESS_STATUS_INITIAL;
        draft.progress.onSite = PROGRESS_STATUS_INITIAL;
        break;
      case SEND_STEP_COMPLETE_PROTOCOL_SYNCHRONIZED:
        draft.progress.sync = PROGRESS_STATUS_COMPLETED;
        draft.progress.pdfs = PROGRESS_STATUS_ON_PROGRESS;
        break;
      case SEND_STEP_COMPLETE_PROTOCOL_PDFS_GENERATED:
        draft.progress.pdfs = PROGRESS_STATUS_COMPLETED;
        draft.progress.emails = PROGRESS_STATUS_ON_PROGRESS;
        break;
      case SEND_STEP_COMPLETE_PROTOCOL_EMAIL_SENT:
        draft.progress.emails = PROGRESS_STATUS_COMPLETED;
        draft.progress.closed = PROGRESS_STATUS_ON_PROGRESS;
        break;
      case SEND_STEP_COMPLETE_PROTOCOL_CLOSED:
        draft.progress.closed = PROGRESS_STATUS_COMPLETED;
        if (action.payload) draft.progress.onSite = PROGRESS_STATUS_ON_PROGRESS;
        break;
      case SEND_STEP_COMPLETE_PROTOCOL_ONSITE_MESSAGE_SYNC:
        draft.progress.onSite = PROGRESS_STATUS_COMPLETED;
        break;
      case SEND_STEP_COMPLETE_PROTOCOL_ONSITE_MESSAGE_SYNC_ERROR:
        draft.progress.onSite = PROGRESS_STATUS_ERROR;
        break;
      case SEND_STEP_COMPLETE_PROTOCOL_ONSITE_MESSAGE_SYNC__AUTH_ERROR:
        draft.progress.onSite = PROGRESS_STATUS_AUTH_ERROR;
        break;
      default:
        return state;
    }
  });
