import { actions as toastrActions } from 'react-redux-toastr';
import { put, takeLatest } from 'redux-saga/effects';

import { MAIN_STEP_ASSIGN_USER_PROTOCOL_ERROR } from './constants';

export function notifyError(message) {
  return {
    type: MAIN_STEP_ASSIGN_USER_PROTOCOL_ERROR,
    payload: message,
  };
}

function* doNotifyError({ payload }) {
  yield put(
    toastrActions.add({
      type: 'error',
      attention: true,
      message: payload,
    }),
  );
}

export function* switchNotifyError() {
  yield takeLatest([MAIN_STEP_ASSIGN_USER_PROTOCOL_ERROR], doNotifyError);
}
