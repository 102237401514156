/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import { routerActions } from 'connected-react-router';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { all, any, keys } from 'ramda';
import { Component, Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { boolean, object, string } from 'yup';
import { i18n } from '../../../common/i18n-loader';
import { colors } from '../../../common/theme/colors';
import { variables } from '../../../common/theme/variables';
import {
  selectIsCurrentProtocolOffline,
  selectIsProtocolReadonly,
  selectProtocolUser,
  selectSendStep,
  selectHasSignedManager,
} from '../redux/selectors';
import Button from '../../common/Button';
import * as commonActions from '../../common/redux/actions';
import {
  selectIsAnySyncOfflineBusy,
  selectIsOnline,
  selectIsSyncOfflineBusy,
  selectIsAroovCustomer,
  selectHasOnSiteWorkflow,
} from '../../common/redux/selectors';
import { validation } from '../../common/Validations';
import { selectCurrentId } from '../redux/selectors';
import { EMail } from './EMail';
import ProgressModal from './ProgressModal';
import * as actions from './redux/actions';
import { useDebouncedCallback } from 'use-debounce';

const styles = {
  container: {
    height: '100%',
    width: '100%',
  },
  form: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: '5fr min-content',
    gridTemplateAreas: '"content" "buttons"',
    gridRowGap: '16px',
    overflow: 'hidden',
  },
  formContent: {
    gridArea: 'content',
    display: 'grid',
    gridTemplateRows: 'min-content min-content',
    overflow: 'overlay',

    padding: `16px ${variables.gutter.md} 8px`,

    [variables.breakpoints.md]: {
      padding: `16px ${variables.gutter.lg} 8px`,
    },
  },
  title: {
    fontWeight: 'bold',
    backgroundColor: colors.lighterGrey,
    padding: '1.5em',
  },
  emails: {
    display: 'grid',
    gridTemplateRows: 'min-content',
  },
  buttons: {
    gridArea: 'buttons',
    alignSelf: 'flex-end',
    display: 'grid',
    gridTemplateColumns: 'auto',
    padding: `8px ${variables.gutter.md} 16px`,

    [variables.breakpoints.md]: {
      padding: `8px ${variables.gutter.lg} 16px`,
    },
    gridGap: '1em',
  },
};

const baseDataMap = {
  oldTenant: { id: 'oldRentalPerson', fieldname: 'VormieterEmail' },
  oldTenant_2: { id: 'oldRentalPerson_2', fieldname: 'VormieterEmail' },
  newTenant: { id: 'newRentalPerson', fieldname: 'NachmieterEmail' },
  newTenant_2: { id: 'newRentalPerson_2', fieldname: 'NachmieterEmail' },
};

const labelMap = (key) => {
  switch (key) {
    case 'oldTenant':
      return i18n._('LABELS.OLD-TENANT');
    case 'oldTenant_2':
      return i18n._('LABELS.OLD-TENANT-2');
    case 'newTenant':
      return i18n._('LABELS.NEW-TENANT');
    case 'newTenant_2':
      return i18n._('LABELS.NEW-TENANT-2');
    case 'manager':
      return i18n._('LABELS.MANAGER');
    case 'otherMails':
      return i18n._('LABELS.OTHER-MAILS');
    default:
      return '';
  }
};

const Field = (props) => {
  const {
    name,
    label,
    value,
    touched,
    error,
    setFieldTouched,
    setFieldValue,
    sendEmail,
    sendAroovEmail,
    readOnly,
    onEmailChange,
    onSendFlagsChange,
    isAroovCustomer,
  } = props;

  const handleBlur = useCallback(() => {
    setFieldTouched(name);
  }, [name, setFieldTouched]);

  const [handleDebouncedChange] = useDebouncedCallback((name, value) => {
    onEmailChange(name, value);
  }, 500);

  const handleChange = useCallback(
    (value) => {
      setFieldValue(name, value);
      handleDebouncedChange(name, value);
    },
    [name, handleDebouncedChange, setFieldValue],
  );

  const handleSendEmailChange = useCallback(
    (name, value) => {
      setFieldValue(`${name}_sendEmail`, value);
      onSendFlagsChange(name, value, 'send');
    },
    [setFieldValue, onSendFlagsChange],
  );

  const handleSendAroovEmailChange = useCallback(
    (name, value) => {
      setFieldValue(`${name}_sendAroovEmail`, value);
      onSendFlagsChange(name, value, 'sendAroov');
    },
    [setFieldValue, onSendFlagsChange],
  );

  return (
    <EMail
      key={name}
      label={label}
      name={name}
      value={value}
      touched={touched}
      error={error}
      onBlur={handleBlur}
      onChange={handleChange}
      sendEmail={sendEmail}
      sendAroovEmail={sendAroovEmail}
      readOnly={readOnly}
      onSendEmailChange={handleSendEmailChange}
      onSendAroovEmailChange={handleSendAroovEmailChange}
      isAroovCustomer={isAroovCustomer}
    />
  );
};

class SendStep extends Component {
  static propTypes = {
    protocolId: PropTypes.string,
    isOnline: PropTypes.bool,
    isProtocolReadonly: PropTypes.bool,
    sendStep: PropTypes.array,
    hasSignedManager: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = { initialValues: null, validationSchema: null };
  }

  getInitialValues() {
    const { sendStep } = this.props;

    if (!!sendStep) {
      return sendStep.reduce((accumulator, current) => {
        accumulator[current.type] = current.emails;
        accumulator[`${current.type}_sendEmail`] = current.send;
        accumulator[`${current.type}_sendAroovEmail`] = current.sendAroov;
        return accumulator;
      }, {});
    }

    return {};
  }

  getValidationSchema() {
    return object().shape({
      oldTenant_sendEmail: boolean(),
      oldTenant: string().when('oldTenant_sendEmail', { is: true, then: validation.email }),
      oldTenant_2_sendEmail: boolean(),
      oldTenant_2: string().when('oldTenant_2_sendEmail', { is: true, then: validation.email }),
      newTenant_sendEmail: boolean(),
      newTenant: string().when('newTenant_sendEmail', { is: true, then: validation.email }),
      newTenant_2_sendEmail: boolean(),
      newTenant_2: string().when('newTenant_2_sendEmail', { is: true, then: validation.email }),
      manager_sendEmail: boolean(),
      manager: string().when('manager_sendEmail', { is: true, then: validation.email }),
      otherMails_sendEmail: boolean(),
      otherMails: string().when('otherMails_sendEmail', {
        is: true,
        then: validation.multipleEmails,
      }),
    });
  }

  readOnly = (key) => {
    switch (key) {
      case 'oldTenant':
      case 'oldTenant_2':
      case 'newTenant':
      case 'newTenant_2':
        return this.props.isProtocolReadonly;
      default:
        return false;
    }
  };

  handleEmailChange = (key, value) => {
    const {
      protocolId,
      actions: { commonPatchOperation, commonDeleteOperation },
    } = this.props;
    const map = baseDataMap[key];

    if (!!map) {
      if (!!value) {
        commonPatchOperation(
          protocolId,
          `basedataInputs/[${map.id}]/fields/[${encodeURIComponent(map.fieldname)}]/value`,
          value,
        );
      } else {
        commonDeleteOperation(
          protocolId,
          `basedataInputs/[${map.id}]/fields/[${encodeURIComponent(map.fieldname)}]/value`,
        );
      }
    } else {
      commonPatchOperation(protocolId, `sendStep/[${key}]`, { emails: value });
    }
  };

  handleSendFlagsChange = (key, value, sendKey) => {
    const {
      protocolId,
      actions: { commonPatchOperation },
    } = this.props;

    if (sendKey === 'send' || sendKey === 'sendAroov')
      commonPatchOperation(protocolId, `sendStep/[${key}]`, { [sendKey]: value });
  };

  handleConfirmSendProtocol = () => {
    const {
      isOnline,
      hasSignedManager,
      hasOnSiteWorkflow,
      sendStep,
      actions: { commonOpenInformationModal, sendStepCompleteProtocol },
    } = this.props;

    if (!isOnline) {
      commonOpenInformationModal({
        title: i18n._('SEND-STEP.INFO-MODAL.TITLE'),
        message: i18n._('MESSAGES.MUST-BE-ON-LINE'),
      });
    } else if (!hasSignedManager) {
      commonOpenInformationModal({
        title: i18n._('SEND-STEP.INFO-MODAL.TITLE'),
        message: i18n._('MESSAGES.PROTOCOL-MUST-BE-SIGN'),
      });
    } else if (all((x) => !x.send)(sendStep)) {
      commonOpenInformationModal({
        title: i18n._('SEND-STEP.INFO-MODAL.TITLE'),
        message: i18n._('MESSAGES.HAS-NO-RECIPENT'),
      });
    } else if (any((x) => !x.emails && x.send)(sendStep)) {
      commonOpenInformationModal({
        title: i18n._('SEND-STEP.INFO-MODAL.TITLE'),
        message: i18n._('MESSAGES.HAS-NO-RECIPENT'),
      });
    } else {
      sendStepCompleteProtocol(hasOnSiteWorkflow);
    }
  };

  componentDidMount() {
    this.setState({
      initialValues: this.getInitialValues(),
      validationSchema: this.getValidationSchema(),
    });
  }

  render() {
    const { isOnline, isAroovCustomer } = this.props;

    const { initialValues, validationSchema } = this.state;

    return (
      <Fragment>
        <div css={styles.container}>
          {!!initialValues && !!validationSchema && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnBlur={true}
              validateOnChange={true}
              validateOnMount={true}
              onSubmit={this.handleConfirmSendProtocol}
            >
              {({ values, errors, touched, setFieldValue, setFieldTouched, handleSubmit }) => (
                <form css={styles.form} autoComplete="off" onSubmit={handleSubmit} noValidate>
                  <div css={styles.formContent}>
                    <div css={styles.title}>
                      <Trans>SEND-STEP.TEXTS.TITLE</Trans>
                    </div>
                    <div css={styles.emails}>
                      {keys(values)
                        .filter(
                          (key) => !key.endsWith('_sendEmail') && !key.endsWith('_sendAroovEmail'),
                        )
                        .map((key) => (
                          <Field
                            key={key}
                            label={labelMap(key)}
                            name={key}
                            value={values[key]}
                            touched={touched[key]}
                            error={errors[key]}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            sendEmail={values[`${key}_sendEmail`]}
                            sendAroovEmail={values[`${key}_sendAroovEmail`]}
                            readOnly={this.readOnly(key)}
                            onEmailChange={this.handleEmailChange}
                            onSendFlagsChange={this.handleSendFlagsChange}
                            isAroovCustomer={isAroovCustomer}
                          />
                        ))}
                    </div>
                  </div>
                  <div css={styles.buttons}>
                    <Button type="submit" disabled={!isOnline}>
                      <Trans>BUTTONS.PROTOCOL-SEND</Trans>
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>

        <ProgressModal />
      </Fragment>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    protocolId: selectCurrentId(state),
    sendStep: selectSendStep(state),
    user: selectProtocolUser(state),
    isOnline: selectIsOnline(state),
    isProtocolReadonly: selectIsProtocolReadonly(state),
    isCurrentProtocolOffline: selectIsCurrentProtocolOffline(state),
    isSyncOfflineBusy: selectIsSyncOfflineBusy(state),
    isAnySyncOfflineBusy: selectIsAnySyncOfflineBusy(state),
    hasSignedManager: selectHasSignedManager(state),
    isAroovCustomer: selectIsAroovCustomer(state),
    hasOnSiteWorkflow: selectHasOnSiteWorkflow(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...routerActions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendStep);
