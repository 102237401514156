import { actions as toastrActions } from 'react-redux-toastr';
import { put, takeLatest } from 'redux-saga/effects';

import { SEND_STEP_COMPLETE_PROTOCOL_ERROR } from './constants';

function* doNotifyError({ payload }) {
  yield put(
    toastrActions.add({
      type: 'error',
      attention: true,
      message: payload,
    }),
  );
}

export function* switchNotifyError() {
  yield takeLatest([SEND_STEP_COMPLETE_PROTOCOL_ERROR], doNotifyError);
}
