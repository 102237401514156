/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { i18n } from '../../common/i18n-loader';
import Button from './Button';
import {
  selectPersonList,
  selectCurrentProtocolHasComponentsToBeReviewed,
  selectIsLeavingAgreementStep,
} from '../acceptance-protocol/redux/selectors';
import { selectCurrentSigner } from './redux/selectors';
import * as actions from './redux/actions';
import SignatureCanvas from 'react-signature-canvas';

const styles = {
  container: {
    display: 'grid',
    gridTemplateRows: 'min-content auto',
    height: '100%',
  },

  infos: {
    display: 'grid',
    gridTemplateColumns: '2fr 5fr',
    about: {
      display: 'grid',
      gridTemplateRows: '1fr 1fr',
      alignItems: 'center',
      paddingLeft: '1em',
      div: {
        gridRow: '1/3',
      },
    },
  },

  buttons: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    gridGap: '1em',
    padding: '1em',
  },
  sketchArea: {
    height: '100%',
    width: '100%',
  },
};
class WriteSign extends Component {
  static propTypes = {
    currentSigner: PropTypes.string,
    personList: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isBlank: true,
    };
    this.sketchRef = createRef();
  }

  handleConfirm = () => {
    const {
      isLeavingAgreementStep,
      currentSigner,
      hasComponentsToBeReviewed,
      actions: { commonConfirmSign, commonErrorSign, commonAbortAndCloseSignPage },
    } = this.props;

    if (hasComponentsToBeReviewed || isLeavingAgreementStep) {
      if (!this.state.isBlank) {
        if (!!this.sketchRef) {
          commonConfirmSign(currentSigner, this.sketchRef.toDataURL());
        } else {
          commonErrorSign(i18n._('ERRORS.SAVING-SIGN'));
          commonAbortAndCloseSignPage();
        }
      } else {
        commonErrorSign(i18n._('ERRORS.EMPTY-SIGN'));
      }
    } else {
      commonErrorSign(i18n._('ERRORS.COMPONENTS-TO-BE-REVIEWED'));
    }
  };

  handleChange = () => {
    this.setState({ isBlank: false });
  };

  handleClear = () => {
    if (!!this.sketchRef) {
      this.sketchRef.clear();
      this.setState({ isBlank: true });
    }
  };

  handleAbortAndClose = () => {
    const {
      actions: { commonAbortAndCloseSignPage },
    } = this.props;

    if (!!this.sketchRef) {
      this.sketchRef.clear();
      commonAbortAndCloseSignPage();
    }
  };

  handleRejectSign = () => {
    const {
      currentSigner,
      actions: { commonRejectSign },
    } = this.props;
    commonRejectSign(currentSigner);
  };

  computeInfoLabel() {
    const { currentSigner, personList } = this.props;

    let translateText = 'LABELS.OLD-TENANT-SIGNATURE';
    switch (currentSigner) {
      case 'oldTenant':
        translateText = 'LABELS.OLD-TENANT-SIGNATURE';
        break;
      case 'newTenant':
        translateText = 'LABELS.NEW-TENANT-SIGNATURE';
        break;
      case 'manager':
        translateText = 'LABELS.MANAGER-SIGNATURE';
        break;
      default:
        return;
    }

    return `${i18n._(translateText)} : ${
      !!personList && !!personList[currentSigner] ? personList[currentSigner].join(', ') : ''
    }`;
  }

  render() {
    const { currentSigner } = this.props;

    return (
      !!currentSigner && (
        <div css={styles.container}>
          <div css={styles.infos}>
            <div css={styles.infos.about}>
              <div>{this.computeInfoLabel()}</div>
            </div>
            <div css={styles.buttons}>
              {currentSigner !== 'manager' ? (
                <Button secondary onClick={this.handleRejectSign}>
                  <Trans>LABELS.REJECT-SIGN</Trans>
                </Button>
              ) : (
                <span />
              )}
              <Button secondary onClick={this.handleAbortAndClose}>
                <Trans>LABELS.ABORT-CLOSE-SIGN</Trans>
              </Button>
              <Button secondary onClick={this.handleClear}>
                <Trans>LABELS.CLEAR-CONTENT</Trans>
              </Button>
              <Button default onClick={this.handleConfirm}>
                <Trans>LABELS.CONFIRM-SIGN</Trans>
              </Button>
            </div>
          </div>
          <div>
            <SignatureCanvas
              ref={(ref) => {
                this.sketchRef = ref;
              }}
              canvasProps={{ style: styles.sketchArea }}
              clearOnResize={false}
              penColor="black"
              minWidth={2}
              maxWidth={2}
              onBegin={this.handleChange}
            />
          </div>
        </div>
      )
    );
  }
}
/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    isLeavingAgreementStep: selectIsLeavingAgreementStep(state),
    currentSigner: selectCurrentSigner(state),
    hasComponentsToBeReviewed: selectCurrentProtocolHasComponentsToBeReviewed(state),
    personList: selectPersonList(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WriteSign);
