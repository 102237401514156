import { t } from '@lingui/macro';

export const invalidLoginError = t('ERRORS.API.INVALID-LOGIN')`Invalid username or password`;
export const badRequestError = t('ERRORS.API.BAD-REQUEST')`Bad Request`;
export const notFoundError = t('ERRORS.API.NOT-FOUND')`Not Found`;
export const unauthorizedError = t('ERRORS.API.UNAUTHORIZED')`Unauthorized`;
export const genericError = t('ERRORS.API.GENERIC')`Error`;
export const unavailableError = t('ERRORS.API.UNAVAILABLE')`Error`;
export const noDataSectionIdError = t('ERRORS.API.NO-DATASECTIONID')`Error`;
export const conflictError = t('ERRORS.API.CONFLICT')`Error`;
export const noTemplateAvailableError = t('ERRORS.API.NO-TEMPLATES-AVAILABLE')`Error`;
