/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import classNames from 'classnames';

import { DatePicker } from '../../common/DatePicker';

const styles = {
  field: {
    width: '100%',
  },
  fieldInput: {
    marginBottom: '0',
  },
};

export const FormDateField = (props) => {
  const { name, label, setFieldValue, setFieldTouched, onChange, value, errors } = props;

  const handleChange = useCallback(
    (value) => {
      setFieldValue(name, value);
      onChange({ currentTarget: { value } });
    },
    [name, setFieldValue, onChange],
  );
  const handleClose = useCallback(() => setFieldTouched(name), [name, setFieldTouched]);

  return (
    <DatePicker
      css={styles.field}
      inheritedInputStyle={styles.fieldInput}
      label={label}
      name={name}
      onClose={handleClose}
      onChange={handleChange}
      value={value}
      className={classNames({
        'has-error': errors,
      })}
    />
  );
};
