import { createMatchSelector } from 'connected-react-router';
import { filter, map, sum, values } from 'ramda';
import { createSelector } from 'reselect';
import { homeRoutes } from '../../home/routes';

const selectCommon = (state) => state.common;

export const selectLanguage = createSelector(selectCommon, (state) => state.language);
export const selectLanguages = createSelector(selectCommon, (state) => state.languages);
export const selectVersion = createSelector(selectCommon, (state) => state.version);
export const selectIsOnline = createSelector(selectCommon, (state) => state.isOnline);
export const selectIsLoggedIn = createSelector(selectCommon, (state) => state?.authInfo?.isLoggedIn);
export const selectIsTokenExpired = createSelector(selectCommon, (state) => state.authInfo.isTokenExpired);
export const selectIsSsoLogin = createSelector(selectCommon, (state) => state.authInfo.isSsoLogin);
export const selectAccessToken = createSelector(selectCommon, (state) => state.authInfo.accessToken);
export const selectRefreshToken = createSelector(selectCommon, (state) => state.authInfo.refreshToken);
export const selectExpiresAt = createSelector(selectCommon, (state) => state.authInfo.expiresAt);
export const selectIdToken = createSelector(selectCommon, (state) => state.authInfo.idToken);
export const selectSessionId = createSelector(selectCommon, (state) => state.sessionId);

export const selectIsNotImplementedModalOpen = createSelector(
  selectCommon,
  (state) => state.ui.modal.notImplemented,
);

export const selectIsConfirmationModalOpen = createSelector(
  selectCommon,
  (state) => state.ui.modal.confirmation,
);

export const selectConfirmationModalTitle = createSelector(
  selectCommon,
  (state) => state.ui.confirmation.title,
);

export const selectConfirmationModalMessage = createSelector(
  selectCommon,
  (state) => state.ui.confirmation.message,
);

export const selectConfirmationModalOk = createSelector(
  selectCommon,
  (state) => state.ui.confirmation.ok,
);

export const selectConfirmationModalCancel = createSelector(
  selectCommon,
  (state) => state.ui.confirmation.cancel,
);

export const selectIsInformationModalOpen = createSelector(
  selectCommon,
  (state) => state.ui.modal.information,
);

export const selectInformationModalTitle = createSelector(
  selectCommon,
  (state) => state.ui.information.title,
);

export const selectInformationModalMessage = createSelector(
  selectCommon,
  (state) => state.ui.information.message,
);

export const selectInformationModalOk = createSelector(
  selectCommon,
  (state) => state.ui.information.ok,
);

export const selectProtocols = createSelector(selectCommon, (state) => state.protocols);

export const selectLeavingAgreements = createSelector(
  selectCommon,
  (state) => state.leavingAgreements,
);

export const selectOfflineProtocols = createSelector(
  selectCommon,
  (state) => state.offlineProtocols,
);

export const selectErrorSynchronizationProtocols = createSelector(
  selectCommon,
  (state) => state.errorSynchronizationProtocols,
);

export const selectPdfs = createSelector(selectCommon, (state) => state.pdfs);

export const selectUi = createSelector(selectCommon, (state) => state.ui);

export const selectBusy = createSelector(selectUi, (ui) => ui.busy);

export const selectIsLoginBusy = createSelector(selectBusy, (busy) => busy.login);

export const selectIsLoginModalOpen = createSelector(selectUi, (ui) => ui.modal.login);

export const selectLoginWorkflow = createSelector(selectCommon, (state) => state.loginWorkflow);

export const selectIsGetProtocolsBusy = createSelector(selectBusy, (busy) => busy.getProtocols);

export const selectIsGetPdfFromProtocolsBusy = createSelector(
  selectBusy,
  (busy) => busy.getPdfsFromProtocol,
);

export const selectIsDeleteProtocolBusy = createSelector(selectBusy, (busy) => busy.deleteProtocol);

export const selectIsQueueBusy = createSelector(selectBusy, (busy) => busy.queue);

export const selectIsMarkAsOfflineBusy = createSelector(selectBusy, (busy) => busy.markAsOffline);

export const selectIsAnyMarkAsOfflineBusy = createSelector(
  selectIsMarkAsOfflineBusy,
  (isMarkAsOfflineBusy) => values(filter((b) => b, isMarkAsOfflineBusy)).length > 0,
);

export const selectIsSyncOfflineBusy = createSelector(selectBusy, (busy) => busy.syncOffline);

export const selectIsAnySyncOfflineBusy = createSelector(
  selectIsSyncOfflineBusy,
  (isSyncOfflineBusy) => values(filter((b) => b, isSyncOfflineBusy)).length > 0,
);

export const selectQueues = createSelector(selectCommon, (state) => state.queues);

export const selectHasAnyQueue = createSelector(
  selectQueues,
  (queues) => sum(map((queue) => queue.operations.length, values(queues))) > 0,
);

export const selectUsername = createSelector(selectCommon, (state) => state.username);

export const selectEmail = createSelector(selectCommon, (state) => state.email);

export const selectClient = createSelector(selectCommon, (state) => state.client);

export const selectIsDevUser = createSelector(
  selectUsername,
  (username) => !!username && username.toLowerCase().endsWith('_dev'),
);

export const selectLocales = createSelector(selectCommon, (state) => state.locales);

export const selectSecurityDepotTypes = createSelector(
  selectCommon,
  (state) => state.securityDepotTypes,
);

export const selectHomeRoute = createSelector(
  (state) => state,
  createMatchSelector({ path: homeRoutes.home }),
);

export const selectIsHome = createSelector(selectHomeRoute, (route) => !!route && route.isExact);

export const selectLoginInfo = createSelector(selectCommon, (state) => state.loginInfo);

export const selectHasAssignationWorkflow = createSelector(
  selectCommon,
  (state) => !!state.clientConfig && state.clientConfig.hasAssignationWorkflow,
);
export const selectHasLeavingAgreement = createSelector(
  selectCommon,
  (state) => !!state.clientConfig && state.clientConfig.hasLeavingAgreement,
);
export const selectHasOnSiteWorkflow = createSelector(
  selectCommon,
  (state) => !!state.clientConfig && state.clientConfig.hasOnSiteWorkflow,
);
export const selectIsAroovCustomer = createSelector(
  selectCommon,
  (state) => !!state.clientConfig && state.clientConfig.isAroovCustomer,
);
export const selectHasInitialAcceptance = createSelector(
  selectCommon,
  (state) => !!state.clientConfig && state.clientConfig.hasInitialAcceptance,
);
export const selectKeyCloakConfig = createSelector(selectCommon, (state) => state.keycloakConfig);

export const selectIsKeycloakActive = createSelector(
  selectCommon,
  (state) => !!state.keycloakConfig && state.keycloakConfig.isKeycloakActive,
);
export const selectKeycloakAuthServerUrl = createSelector(selectCommon, (state) =>
  !!state.keycloakConfig ? state.keycloakConfig.keycloakAuthServerUrl : null,
);

export const selectCurrentSigner = createSelector(selectCommon, (state) => state.currentSigner);

export const selectSentryErrors = createSelector(selectCommon, (state) => state.sentryErrors);

export const selectKeycloak = createSelector(selectCommon, (state) => state.keycloak);

export const selectIsKeycloakConfigLoading = createSelector(selectBusy, (busy) => busy.config);

export const selectIsTenancyAgreementLoading = createSelector(
  selectCommon,
  (state) => state.ui.busy.isTenancyAgreementLoading,
);

export const selectTenancyAgreementEligibility = (protocolId) => createSelector(
  selectCommon,
  (state) => {
    const isMBusEnabled = !!state.clientConfig?.isMBusEnabled;
    const protocols = state.protocols;
    const { rentalData, isWithRemReference } = !!protocols && protocols.find(p => p.id === protocolId);

    const nachmieter = !!rentalData && !!rentalData.nachmieterName && typeof rentalData.nachmieterName === 'string' && rentalData.nachmieterName.trim();
    const vormieter = !!rentalData && !!rentalData.vormieterName && typeof rentalData.vormieterName === 'string' && rentalData.vormieterName.trim();

    return isMBusEnabled && (nachmieter || vormieter) && !!isWithRemReference;
  }
);

export const selectTenancyAgreementSuccess = (protocolId) => createSelector(
  selectCommon,
  (state) => {
    const protocol = state.protocols.find(protocol => protocol.id === protocolId);
    return !!protocol?.isTenancyAgreementSuccess;
  }
);
