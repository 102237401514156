import React from 'react';

export const OutArrowIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 18"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <path d="M9,18a.84.84,0,0,0,.38-.08A1,1,0,0,0,10,17V13H23V11H9a1,1,0,0,0-1,1v2.59L2.41,9,8,3.41V6A1,1,0,0,0,9,7H23V5H10V1A1,1,0,0,0,9.38.08,1,1,0,0,0,8.29.29l-8,8a1,1,0,0,0,0,1.42l8,8A1,1,0,0,0,9,18Z" />
  </svg>
);
