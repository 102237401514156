import Keycloak from 'keycloak-js';
import produce from 'immer';
import { call, put, putResolve, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getConfigForClient } from '../services/config';
import {
  COMMON_LOAD_KEYCLOAK_CONFIG,
  COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS,
  COMMON_LOAD_KEYCLOAK_CONFIG_ERROR,
  COMMON_KEYCLOAK_UNSET,
  COMMON_KEYCLOAK_SET,
  COMMON_KEYCLOAK_EVENT,
  COMMON_KEYCLOAK_DO_LOGIN,
  COMMON_KEYCLOAK_TOKENS,
  COMMON_LOGIN_ERROR,
  COMMON_KEYCLOAK_TOKENS_ERROR,
  COMMON_KEYCLOAK_TOKENS_SUCCESS,
  COMMON_REMOVE_KEYCLOAK_TOKENS,
  COMMON_LOGIN_SUCCESS,
  COMMON_TOKENS_EXPIRED,
} from "./constants";
import { selectIsKeycloakActive, selectIsOnline, selectKeycloak } from "./selectors";
import { authLogout, keycloakAuthLogin } from '../services/auth';
import { KEYCLOAK_REALM } from '../../../common/constants';
import { v4 as uuid } from 'uuid';
import { get } from "../services/api";

export function commonKeycloakOnSet(keycloak) {
  return {
    type: COMMON_KEYCLOAK_SET,
    payload: { keycloak },
  };
}

export function commonKeycloakOnEvent(event, error) {
  return {
    type: COMMON_KEYCLOAK_EVENT,
    payload: { event: event, error: error },
  };
}

export function commonKeycloakOnTokens(tokens, workflow) {
  return {
    type: COMMON_KEYCLOAK_TOKENS,
    payload: { tokens: tokens, workflow: workflow },
  };
}

export function commonLoadKeycloakConfig() {
  return {
    type: COMMON_LOAD_KEYCLOAK_CONFIG,
  };
}

export function commonKeycloakDoLogin() {
  return {
    type: COMMON_KEYCLOAK_DO_LOGIN,
  };
}

export function commonSetTokenExpired(isExpired) {
  return {
    type: COMMON_TOKENS_EXPIRED,
    payload: isExpired,
  };
}

function* handleKeycloakEvent({ payload: { event } }) {
  try {
    // Use the select effect to get the current Keycloak instance from the Redux store
    const keycloak = yield select(selectKeycloak);
    const isOnline = yield select(selectIsOnline);

    if (isOnline && keycloak && (event === 'onAuthError' || event === 'onAuthRefreshError')) {
      // use the Keycloak instance
      yield call([keycloak, keycloak.login]);
    }
  } catch (error) {
    console.error('Error handling Keycloak event:', error);
  }
}

export function* handleKeycloakTokens({ payload: { tokens, workflow } }) {
  if (!tokens || !tokens.token) {
    yield put(removeKeycloakTokens()); // This removes the tokens from the Redux store if not present
    return;
  }

  try {
    const response = yield call(keycloakAuthLogin, tokens);

    yield put({
      type: COMMON_KEYCLOAK_TOKENS_SUCCESS,
      payload: { workflow: workflow, username: response.username, email: response.email, client: response.client },
    });
    
    const locales = yield call(get, 'locale');
    const clientConfig = yield call(get, 'clientconfig');

    let securityDepotTypes = [];
    try {
      securityDepotTypes = yield call(get, 'letting/securitydeposittypes');
    } catch (error) {
      console.error(error.message);
    }

    yield put({
      type: COMMON_LOGIN_SUCCESS,
      payload: {
        workflow,
        username: response.username,
        email: response.email,
        locales,
        securityDepotTypes,
        client: response.client,
        clientConfig,
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_KEYCLOAK_TOKENS_ERROR,
      payload: error.message,
    });

    yield putResolve({
      type: COMMON_LOGIN_ERROR,
      payload: error.message,
    });

    // Remove tokens since there was an error
    yield put(removeKeycloakTokens());
  }
}

function* setKeycloakConfig() {
  try {
    const keycloakConfig = yield call(getConfigForClient);

    yield put({
      type: COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS,
      payload: {
        keycloakConfig,
      },
    });

    if (keycloakConfig.isKeycloakActive) {
      const keycloak = new Keycloak({
        realm: KEYCLOAK_REALM,
        clientId: keycloakConfig.clientId,
        url: keycloakConfig.keycloakAuthServerUrl,
      });

      yield put({
        type: COMMON_KEYCLOAK_SET,
        payload: {
          keycloak,
        },
      });
    }
  } catch (error) {
    yield put({
      type: COMMON_LOAD_KEYCLOAK_CONFIG_ERROR,
      payload: error.message,
    });
  }
}

function* doKeycloakLogin() {
  authLogout();

  const isKeycloakActive = yield select(selectIsKeycloakActive);
  const keycloak = yield select(selectKeycloak);
  const isOnline = yield select(selectIsOnline);

  if (isOnline && isKeycloakActive && keycloak) {
    yield call([keycloak, keycloak.login]);
  }
}

export function* switchKeycloakClient() {
  yield takeLatest(COMMON_KEYCLOAK_EVENT, handleKeycloakEvent);
  yield takeLatest(COMMON_LOAD_KEYCLOAK_CONFIG, setKeycloakConfig);
  yield takeLatest(COMMON_KEYCLOAK_TOKENS, handleKeycloakTokens);
  yield takeEvery(COMMON_KEYCLOAK_DO_LOGIN, doKeycloakLogin);
  yield put(commonLoadKeycloakConfig());
}

export function removeKeycloakTokens() {
  return {
    type: COMMON_REMOVE_KEYCLOAK_TOKENS,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_LOAD_KEYCLOAK_CONFIG:
        draft.ui.busy.config = true;
        break;
      case COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS:
        draft.ui.busy.config = false;
        draft.authInfo.isSsoLogin = action.payload.keycloakConfig.isKeycloakActive;
        draft.keycloakConfig = action.payload.keycloakConfig;

        if (action.payload.keycloakConfig.client) {
          draft.client = action.payload.keycloakConfig.client;
        }
        break;
      case COMMON_LOAD_KEYCLOAK_CONFIG_ERROR:
        draft.ui.busy.config = false;
        draft.client = '';
        draft.keycloak = null;
        draft.keycloakConfig = null;
        draft.authInfo.isSsoLogin = false;
        break;
      case COMMON_KEYCLOAK_SET:
        draft.keycloak = action.payload.keycloak;
        break;
      case COMMON_KEYCLOAK_UNSET:
        draft.ui.busy.config = false;
        draft.client = '';
        draft.keycloak = null;
        draft.keycloakConfig = null;
        draft.authInfo.isSsoLogin = false;
        break;
      case COMMON_KEYCLOAK_TOKENS:
        break;
      case COMMON_KEYCLOAK_DO_LOGIN:
        draft.authInfo.isLoggedIn = false;
        break;
      case COMMON_KEYCLOAK_TOKENS_SUCCESS:
        draft.authInfo.isLoggedIn = true;
        draft.sessionId = uuid();
        draft.username = action.payload.username;
        draft.email = action.payload.email;
        
        if (draft.loginInfo.lastUsername !== action.payload.username){
          draft.protocols = [];
          draft.queues = {};
          draft.offlineProtocols = [];
          draft.errorSynchronizationProtocols = [];
        }
        break;
      case COMMON_KEYCLOAK_TOKENS_ERROR:
        draft.authInfo.isLoggedIn = false;
        break;
      case COMMON_REMOVE_KEYCLOAK_TOKENS:
        if (draft.keycloak) {
          draft.keycloak.token = null;
          draft.keycloak.idToken = null;
          draft.keycloak.refreshToken = null;
        }
        break;
      case COMMON_TOKENS_EXPIRED: 
        draft.authInfo.isTokenExpired = action.payload;
        break;
      default:
        return state;
    }
  });
